import { checkUserRole } from '@/utils/roleUtils';

export default function({ route, store, redirect, error, from }) {
    // When user open first page from and route are equivalent
    if (from.name !== route.name) {
        store.commit('setBeforeRouteName', route.name);
    }

    checkUserRole(store, redirect, route, error);
}
