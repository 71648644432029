<template>
    <v-menu
        v-model="showNotifications"
        bottom
        left
        transition="slide-y-transition"
        :close-on-content-click="false"
    >
        <template v-slot:activator="{ on }">
            <v-btn
                icon
                class="notification-btn"
                v-on="on"
                @click="onNotificationsClick"
            >
                <i class="fad fa-bell fa-lg"/>
            </v-btn>
        </template>
        <v-card v-if="!$_isMobile" class="overflow-hidden">
            <div class="notification-list-header">
                <v-subheader>{{ $t('c_notification.c_title.c_notification_title') }}</v-subheader>
                <v-subheader class="mark-as-read-all" @click="markAllNotificationsAsRead">
                    {{ $t('c_notification.c_buttons.c_markAllAsRead') }}
                </v-subheader>
            </div>
            <v-sheet
                id="scrolling-techniques-8"
                class="overflow-y-auto"
                max-height="400"
            >
                <v-list class="notification-list pt-0">
                    <v-overlay :value="showOverlay">
                        <v-progress-circular indeterminate size="64"/>
                    </v-overlay>
                    <div v-if="unreadNotifications.length === 0" class="notification-wrapper">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>{{ $t('c_notification.c_noNotifications') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                    <div
                        v-for="(notification, index) in unreadNotifications"
                        v-else
                        :key="index"
                        class="notification-wrapper"
                    >
                        <v-list-item>
                            <v-list-item-content>
                                <div>
                                    <nuxt-link
                                        v-if="notification.action_url"
                                        :to="`/${notification.action_url}`"
                                        @click.native="markAsRead(notification.id), showNotifications = false"
                                    >
                                        {{ notification.message }}
                                    </nuxt-link> <span v-else>{{ notification.message }}</span>
                                </div>
                                <v-list-item-subtitle>
                                    <span>{{ notification.createdAtFormatted }}</span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-tooltip right open-delay="600">
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            class="mark-as-read-btn"
                                            icon
                                            v-on="on"
                                            @click="markAsRead(notification.id)"
                                        >
                                            <i class="fal fa-archive fa-lg"/>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('c_notification.c_buttons.c_markAsRead') }}</span>
                                </v-tooltip>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider/>
                    </div>
                </v-list>
            </v-sheet>
            <v-footer class="notification-footer">
                <nuxt-link :to="{ name: 'notifikace' }" @click.native="showNotifications = false">
                    {{ $t('c_notification.c_buttons.c_show_all_notification') }}
                </nuxt-link>
            </v-footer>
        </v-card>
    </v-menu>
</template>

<script>
    import moment from 'moment';
    import tokenMixin from '@/mixins/tokenMixin';
    import cssBreakpointsMixin from '@/mixins/cssBreakpointsMixin';
    import { getUserID } from '@/utils/localStorageUtils';

    export default {
        name: 'AppNotifications',
        mixins: [tokenMixin, cssBreakpointsMixin],
        data() {
            return {
                showNotifications: false,
                unreadNotifications: [],
                showOverlay: false,
                userId: ''
            };
        },
        computed: {
            notificationsCount() {
                return this.$store.getters.getNotificationsCount;
            }
        },
        watch: {
            async showNotifications() {
                if (this.showNotifications) {
                    try {
                        await this.$_checkTokenExpiration();
                        await this.fetchNotifications();
                    } catch (error) {
                        this.$_handleConnectionError(error);
                    }
                }
            }
        },
        mounted() {
            this.userId = getUserID();
        },
        methods: {
            async fetchNotifications() {
                try {
                    ({ notifications: this.unreadNotifications } =
                        await this.$axios.$get(`/users/${this.userId}/notifications`, { params: {
                            take: this.notificationsCount,
                            includeSeen: false
                        }
                        }));
                    this.unreadNotifications.forEach((notification, index) => {
                        this.unreadNotifications[index].createdAtFormatted = moment(notification.created_at)
                            .format('DD. MM. YYYY');
                    });
                } catch (error) {
                    this.$_handleConnectionError(error);
                } finally {
                    this.showOverlay = false;
                }
            },
            async markAsRead(notificationId) {
                try {
                    await this.$_checkTokenExpiration();
                    await this.$axios.$post(`/users/${this.userId}/notifications/${notificationId}/seen`);
                    const notificationsCount = await this.$axios.$get(`/users/${this.userId}/notifications/metadata`);
                    this.$store.commit('setNotificationsCount', notificationsCount.metadata.unread_count);
                    await this.fetchNotifications();
                } catch (error) {
                    this.$_handleConnectionError(error);
                }
            },
            async markAllNotificationsAsRead() {
                try {
                    await this.$_checkTokenExpiration();
                    for (const notification of this.unreadNotifications) {
                        await this.$axios.$post(`/users/${this.userId}/notifications/${notification.id}/seen`);
                    }
                    this.$store.commit('setNotificationsCount', 0);
                    await this.fetchNotifications();
                } catch (error) {
                    this.$_handleConnectionError(error);
                }
            },
            onNotificationsClick() {
                this.$_isMobile
                    ? this.$router.push({ name: 'notifikace' })
                    : this.showNotifications = true;
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import '../assets/scss/variables';

    .notification-list {
        .v-list-item__content > * {
            line-height: 1.4;
        }

        hr {
            margin: 0;
        }
    }

    .notification-btn {
        outline: none;
    }

    .notification-list-header {
        display: flex;
        justify-content: space-between;
        top: 0;
        background: color('light-gray');
        border-bottom: 1px solid gainsboro;
        z-index: 1;
    }

    .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
        color: gray!important;
    }

    .v-menu__content--fixed {
        max-width: 30rem;
        margin-top: 3.5rem;
    }

    .v-list-item__content > *:not(:last-child) {
        margin-bottom: 8px
    }

    .notification-footer.theme--light.v-footer  {
        justify-content: center;
        border-top: 1px solid gainsboro;

        a {
            color: color('primary');
            text-decoration: none;
            font-weight: 500;

            &:hover{
            text-decoration: underline;
            }
        }
    }

    .mark-as-read-all {
        cursor: pointer;
        color: color('primary');

        &:hover{
            text-decoration: underline;
        }
    }

    .v-subheader {
        font-size: 1rem;
        font-weight: 500;
    }

    .unread {
        background: color('secondary');

        .v-list-item__content {
            color: color('primary')
        }
    }

    .mark-as-read-btn.theme--light.v-btn.v-btn--icon {
        color: color('primary');
    }

    .notification-wrapper {
        width: 30rem;

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        &:last-child > hr {
            display: none;
        }
    }

    @media (max-width: breakpoint('sm')) {
        .v-menu__content {
            display: none;
        }
    }
</style>
