import moment from 'moment';
import {
    getAccessTokenExpiresAt,
    getRefreshPage,
    getShowAuthLoginModal,
    setRefreshPage,
    softLogout, clearLocalStorage
} from '@/utils/localStorageUtils';
import { routeWithQueryParams } from '@/utils/stringUtils';

export default {
    data() {
        return {
            accessTokenExpiresAt: '',
            momentAccessTokenExpiresAt: '',
            storageShowAuthLoginModal: ''
        };
    },
    mounted() {
        this.accessTokenExpiresAt = getAccessTokenExpiresAt();
        this.momentAccessTokenExpiresAt = moment(this.accessTokenExpiresAt).add(-10, 'minutes');
        this.storageShowAuthLoginModal = getShowAuthLoginModal();
    },
    computed: {
        showAuthLoginModal() {
            return this.$store.getters.getShowAuthLogin;
        }
    },
    watch: {
        showAuthLoginModal() {
            if (!this.showAuthLoginModal) {
                this.accessTokenExpiresAt = getAccessTokenExpiresAt();
                this.momentAccessTokenExpiresAt = moment(this.accessTokenExpiresAt).add(-10, 'minutes');
            }
        }
    },
    methods: {
        $_userLogout() {
            this.$store.commit('logout');
            clearLocalStorage();
            this.$router.push({ name: 'index', query: { loginRedirectUrl: routeWithQueryParams(this.$route.path, this.$route.query) } });
        },
        $_checkTokenExpiration() {
            if (this.storageShowAuthLoginModal !== 'true') {
                // Check whether token expiration time is one minute before actual time
                const isAccessTokenExpired = moment(this.accessTokenExpiresAt).add(-10, 'minutes').isBefore(moment());
                if (isAccessTokenExpired) {
                    this.$_userLogout();
                } else {
                    return Promise.resolve();
                }
            } else {
                return new Error('need authentication');
            }
        },
        $_checkTokenValidity() {
            if (this.storageShowAuthLoginModal !== 'true') {
                // Check whether token expiration time is one minute before actual time
                const isAccessTokenExpired = moment(this.accessTokenExpiresAt).add(-10, 'minutes').isBefore(moment());
                if (isAccessTokenExpired) {
                    this.$_userLogout();
                }
                return !isAccessTokenExpired;
            } else {
                throw new Error('need authentication');
            }
        },
        $_checkAccessTokenExpiration() {
            // Check whether token expiration time ten minutes before actual time
            const isAccessTokenExpired = this.momentAccessTokenExpiresAt.isBefore(moment());
            if (isAccessTokenExpired) {
                softLogout();
                this.$store.commit('setShowAuthLoginModal', true);
            }
        },
        async $_revokeToken(logoutAllDevices) {
            await this.$axios.$delete('/auth', { data: { 'all_devices': logoutAllDevices } });
        },
        $_handleConnectionError(error) {
            if (error) {
                if (error.response) {
                    // Reload page in case 401 error save info about reloading, avoid infinite loop
                    if (error.response.status === 401 && !getRefreshPage()) {
                        setRefreshPage();
                        location.reload();
                    // In case page has been reloaded but still get 401 error - logout user
                    } else if (error.response.status === 401 && getRefreshPage()) {
                        this.$_userLogout();
                    }
                }
            } else {
                this.$notify({
                    group: 'app',
                    type: 'error',
                    duration: this.$store.getters.getNotificationDurationTime,
                    title: this.$t('c_notifications.c_error'),
                    text: this.$t('c_notifications.c_serverCommunication')
                });
            }
        }
    }
};
