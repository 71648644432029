<template>
    <div id="app-bar">
        <v-app-bar
            :app="!$_isMobile"
            clipped-left
            :height="$_isMobile ? 80 : 100"
            color="white"
        >
            <v-app-bar-nav-icon @click="onNavIconClick"/>
            <img
                v-if="!$_isMobile"
                :class="{ 'move-right': logoMoveRight }"
                class="logo"
                src="/img/logo_clk_1.svg"
            >
            <v-spacer/>
            <v-badge :value="!!notificationsCount">
                <template v-slot:badge>{{ notificationsCount }}</template>
                <app-notifications/>
            </v-badge>
            <v-menu
                v-model="showMenu"
                :offset-y="offset"
                max-width="200"
                transition="slide-y-transition"
            >
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        <div v-if="userInfo">
                            <v-btn
                                v-if="$_isMobile"
                                icon
                                class="mobile-user"
                            >
                                <i class="fas fa-user fa-lg"/>
                            </v-btn>
                            <div v-if="!$_isMobile" id="user-menu">
                                {{ userInfo.full_name }} <v-icon :class="{ 'arrow-up': showMenu }"> mdi-chevron-down </v-icon>
                            </div>
                        </div>
                    </div>
                </template>
                <v-list class="menu-list">
                    <v-list-item
                        v-for="item in appBarMenu"
                        :key="item.id"
                        @click="onItemClick(item)"
                    >
                        <i :class="item.icon"/> {{ item.label }}
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
    </div>
</template>

<script>
    import AppNotifications from './AppNotifications';
    import appBarMenuMixin from '@/mixins/appBarMenuMixin';
    import notificationsMixin from '@/mixins/notificationsMixin';
    import tokenMixin from '@/mixins/tokenMixin';
    import cssBreakpointsMixin from '@/mixins/cssBreakpointsMixin';
    import { getUserInfo } from '@/utils/localStorageUtils';

    export default {
        name: 'AppBar',
        components: { AppNotifications },
        mixins: [appBarMenuMixin, notificationsMixin, tokenMixin, cssBreakpointsMixin],
        data() {
            return {
                showMenu: false,
                offset: true,
                logoMoveRight: false,
                logoMoveLeft: true,
                userInfo: null
            };
        },
        computed: {
            appBarMenu() {
                return this.$store.getters.getAppBarMenu;
            },
            notificationsCount() {
                return this.$store.getters.getNotificationsCount;
            },
            showLoginModal() {
                return this.$store.getters.getShowAuthLogin;
            },
            updateProfileCount() {
                return this.$store.getters.getUpdateProfileCount;
            }
        },
        watch: {
            showLoginModal() {
                if (!this.showLoginModal) {
                    this.userInfo = getUserInfo();
                }
            },
            updateProfileCount() {
                this.userInfo = getUserInfo();
            }
        },
        mounted() {
            this.userInfo = getUserInfo();
        },
        methods: {
            onNavIconClick() {
                this.$emit('nav-icon-click');
                if (this.$_isDesktop) {
                    this.logoMoveRight = !this.logoMoveRight;
                }
            },
            onItemClick(item) {
                if (item.action.name === 'route') {
                    this.$router.push({ name: item.action.routeName });
                } else {
                    this.$store.commit('setLogoutClick', true);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import '../assets/scss/variables';

    #app-bar {
       ::v-deep .v-badge {
           position: relative;

           .v-badge__badge {
               position: absolute;
               top: 0 !important;
               right: 5px !important;
               left: 25px !important;
           }
       }

       ::v-deep .v-toolbar__content, .v-toolbar__extension{
            padding: 0 2rem;
        }

        .v-toolbar {
            box-shadow: 0 0 6px rgba(0,0,0,.2)
        }
    }

    #user-menu {
        display: flex;
        cursor: pointer;
        margin-left: 2rem
    }

    .logo {
        margin-left: 1rem;
        animation-name: move-to-left;
        animation-timing-function: ease;
        animation-duration: 0.35s;
        animation-fill-mode: forwards;
        max-width: 17rem;
    }

    .v-menu__content--fixed {
        max-height: 20rem;
        margin-top: 1rem;
    }

    .mobile-user {
        color: gray;
    }

    .menu-list {
        .fa-lg {
            margin-right: 1rem;
        }
    }

    @keyframes move-to-left {
        0% {margin-left: 2rem}
        100% {margin-left: 1rem}
    }

    @keyframes move-to-right {
        0% {margin-left: 1rem}
        100% {margin-left: 2rem}
    }

    .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
        color: gray!important;
    }

    .v-menu__content {
         box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 7px
    }

    .arrow-up {
        transform: rotateX(180deg);
    }

    .v-menu__content--fixed {
        left: auto !important;
        right: 30px !important;
    }

    @media (min-width: breakpoint('md')) {
        .move-right {
            animation-name: move-to-right;
            animation-timing-function: ease;
            animation-duration: 0.35s;
            animation-fill-mode: forwards;
        }
    }

    //IE styles
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .logo {
            max-height: 5rem;
        }
    }

</style>
