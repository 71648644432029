import moment from 'moment';
import { i18n } from '@/plugins/i18n';

export function czechFormatDates(startsArg, endsArg) {
    if (!startsArg || !endsArg) { return ''; }
    const startsOn = moment(startsArg);
    const endsOn = moment(endsArg);
    if (startsOn.year() !== endsOn.year()) {
        return startsOn.format('D. M. YYYY') + '–' + endsOn.format('D. M. YYYY');
    }
    if (startsOn.month() !== endsOn.month()) {
        return startsOn.format('D. M.') + '–' + endsOn.format('D. M. YYYY');
    }
    if (!startsOn.isSame(endsOn, 'day')) {
        return startsOn.format('D.') + '–' + endsOn.format('D. M. YYYY');
    }
    return moment(startsOn).format('D. M. YYYY');
}

export function czechFormatDate(date) {
    return moment(date).format('DD. MM. YYYY');
}

export function czechFormatDateTime(date) {
    return moment(date).format('DD. MM. YYYY HH:mm:ss');
}

export function czechFormatDateTimeMinutes(date) {
    return moment(date).format('DD. MM. YYYY HH:mm');
}

export function getCountdown(timeToFinish) {
    // Difference between actual time and starts time
    const timeToFinishDiff = moment.duration(moment(timeToFinish).diff(moment()));
    // Prepare time
    const minutes = timeToFinishDiff.minutes() > 9 ? timeToFinishDiff.minutes() : `0${timeToFinishDiff.minutes()}`;
    const seconds = timeToFinishDiff.seconds() > 9 ? timeToFinishDiff.seconds() : `0${timeToFinishDiff.seconds()}`;
    const time = `${timeToFinishDiff.hours()}:${minutes}:${seconds}`;
    // Return countdown
    return timeToFinishDiff.days()
        ? `${timeToFinishDiff.days()} ${i18n.tc('c_cmcWebinars.c_days', timeToFinishDiff.days())} ${time}`
        : time;
}
