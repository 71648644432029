<template>
    <v-app id="oauth">
        <!-- global instant notifications -->
        <notification-messages/>
        <!-- global instant notifications maintenance group -->
        <notification-messages group="maintenance"/>
        <div class="content">
            <nuxt/>
        </div>
        <app-footer/>

        <!-- Cookies confirm-->
        <cookies-settings/>
    </v-app>
</template>

<script>
    import { mapGetters } from 'vuex';
    import AppFooter from '@/components/AppFooter';
    import CookiesSettings from '../components/CookiesSettings.vue';
    import NotificationMessages from '@/components/NotificationMessages';
    import notificationsMixin from '@/mixins/notificationsMixin';
    import { getAccessToken } from '@/utils/localStorageUtils';

    export default {
        components: { AppFooter, NotificationMessages, CookiesSettings },
        mixins: [notificationsMixin],
        computed: {
            ...mapGetters(['getBackendMaintenance'])
        },
        watch: {
            getBackendMaintenance() {
                if (this.getBackendMaintenance) {
                    this.$_notification(this.$t('c_notifications.c_maintenance'), 'maintenance', this.$t('c_notifications.c_maintenanceTitle'), -1, 'maintenance');
                } else {
                    this.$_clearNotifications('maintenance');
                }
            }
        },
        beforeCreate() {
            // Set token for all requests
            this.$axios.setToken(getAccessToken(), 'Bearer');
        }
    };
</script>

<style lang="scss" scoped>
    @import '../assets/scss/variables';

    #oauth {
        background: url("/img/registration_bcg.png");
        background-size: cover;

        .error-messages-wrapper {
            margin-top: 2rem;
            margin-bottom: -2rem;
        }

        .content {
            display: flex;
            justify-content: center;

            .error-message-box {
                margin: 1rem 0;
            }

        }

        ::v-deep #not-found {
            background: white;
            border-radius: 1rem;
            padding: 3rem;
            box-shadow: $box-shadow-sm;
            margin-bottom: 5rem;
        }

         @media (max-width: 1279px) {
            .content {
                margin-bottom: 40rem;
                padding: 1rem;
            }

            ::v-deep #not-found {
                padding: 1rem;
            }
        }

        @media screen and (max-width: 320px) {
            .content {
                margin-bottom: 45rem;
            }
        }
    }

    footer {
        width: 100%;
        background: white;
        a {
            text-decoration: none;
            &:hover {
                text-decoration: none;
            }
        }
        .col {
            padding: 1rem 2rem 1rem 2rem;
        }
    }

    .v-application .v-application--wrap {
        border: 1px solid;
    }

    .footer-title {
        margin-bottom: 1rem
    }

    .footer-icon {
        margin-bottom: 0.5rem;
        a {
            &:hover {
                text-decoration: none;
            }
        }
        span {
            padding-left: 1rem;
        }
        &:hover {
            .mail {
                text-decoration: underline;
            }
        }
    }

    .footer-text {
        text-align: center;
        small {
            background: color('light-gray');
            padding: 5px 10px;
            border-radius: 1.5rem;
        }
    }

    .message-wrapper {
        margin: auto;
        margin-top: 2rem;
    }

    .content {
        margin: 0 auto 20rem auto;
    }
</style>
