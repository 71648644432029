<template>
    <div class="fix">
        <div class="over">
            <div id="landing-page-header" class="header">
                <div class="bg">
                    <div class="inner">
                        <a href="/" class="logo">{{ $t('c_landing.c_header.c_title') }}</a>
                        <div class="menu">
                            <ul>
                                <li class="icon ic_reg"><a @click="$emit('registration-click')">{{ $t('c_landing.c_header.c_registration') }}</a></li>
                                <li class="icon ic_log openlayer"><a @click="$emit('login-click')">{{ $t('c_landing.c_header.c_login') }}</a></li>
                                <li class="ic_menu" @click="$emit('menu-click')"><a>{{ $t('c_landing.c_header.c_menu') }}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LandingHeader'
    };
</script>
