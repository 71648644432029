<template>
    <div class="inner position-relative">
        <h2 class="frank-bold">{{ $t('c_common.c_titles.c_forgotPassword') }}</h2>
        <div class="overinput">
            <p>{{ $t('c_landing.c_forgotPassword.c_forgotPasswordText') }}</p>
            <div class="send-new-password-wrapper">
                <div class="input-wrapper">
                    <input
                        ref="email"
                        v-model="credentials.email"
                        type="email"
                        :class="{'is-invalid': isEmailInvalid}"
                        @blur="dirtyEmail = true"
                    >
                    <div class="invalid-feedback" :class="{'d-block': isEmailInvalid}">
                        {{ $getFieldError($v.credentials.email) }}
                    </div>
                </div>
                <button class="send-new-password" @click="onSendClick">{{ $t('c_common.c_buttons.c_send') }}</button>
            </div>
            <a class="fright back-to-login-btn" @click="$emit('back')"> <v-icon color="primary">mdi-chevron-left</v-icon> <span> {{ $t('c_common.c_buttons.c_backToLogin') }}</span> </a>
        </div>
    </div>
</template>

<script>
    import tokenMixin from '@/mixins/tokenMixin';
    import { email, required, vuelidateMessagesMixin } from '@/mixins/vuelidateMessagesMixin';

    export default {
        name: 'AppForgetPassword',
        mixins: [vuelidateMessagesMixin, tokenMixin],
        props: {
            credentials: {
                type: Object,
                required: true
            }
        },
        validations() {
            return {
                credentials: {
                    email: {
                        required: required(),
                        email: email()
                    }
                }
            };
        },
        data() {
            return {
                dirtyEmail: false
            };
        },
        computed: {
            isEmailInvalid() {
                return this.$v.credentials.email.$invalid && (this.dirtyEmail || this.$v.credentials.email.$error);
            }
        },
        methods: {
            async onSendClick() {
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    try {
                        await this.$axios.$post('/auth/forgotten-password', { email: this.credentials.email });
                        this.$emit('submit');
                    } catch (error) {
                        this.$_handleConnectionError(error);
                    }
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/variables";

    .v-application .v-application--wrap .landing-page-wrapper .layer h2 {
        margin-bottom: 3rem;
    }

    .send-new-password-wrapper{
        display: flex;

        .input-wrapper {
            width: 100%;
        }
    }

    .v-application .v-application--wrap .landing-page-wrapper .layer input {
        margin-bottom: 0.5rem;
    }

    .v-application .v-application--wrap .landing-page-wrapper .layer button.send-new-password {
            border-radius: 0;
            height: 3.7rem;
            border: 1px solid color('primary');
            background: color('primary');
            color: white;
    }

    .v-application .v-application--wrap .landing-page-wrapper .layer a.fright.back-to-login-btn {
        float: left;
        &:hover {
              text-decoration: none
            }
        span {
             &:hover {
              text-decoration: underline;
            }
        }
    }

    .d-block {
        display: block !important;
    }

    .invalid-feedback {
        display: none;
        color: #ff5252;
        font-size: 0.875rem;
    }

     @media (max-width: breakpoint('md')) {
        .send-new-password-wrapper{
            display: block;
        }

        .v-application .v-application--wrap .landing-page-wrapper .layer input {
            margin-bottom: 1rem;
        }

         .invalid-feedback {
            text-align: left;
            margin-bottom: 1rem;
         }
     }

</style>