export function deepCopy(array) { return JSON.parse(JSON.stringify(array)); }

export function sortByProperty(array, propName) {
    return array.sort((a, b) => {
        const nameA = a[propName].toUpperCase(); // ignore upper and lowercase
        const nameB = b[propName].toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        // names must be equal
        return 0;
    });
}