export function addHtmlClass(cssClass) {
    let strHtmlClasses = document.getElementsByTagName('html')[0].getAttribute('class');

    if (!strHtmlClasses) {
        strHtmlClasses = cssClass;
    } else {
        const arrHtmlClasses = strHtmlClasses.split(' ');
        const cssClassIndex = arrHtmlClasses.findIndex(itemClass => itemClass === cssClass);
        if (cssClassIndex === -1) {
            strHtmlClasses = strHtmlClasses + ' ' + cssClass;
        } else {
            return;
        }
    }

    document.getElementsByTagName('html')[0].setAttribute('class', strHtmlClasses);
}

export function removeHtmlClass(cssClass) {
    let strHtmlClasses = document.getElementsByTagName('html')[0].getAttribute('class');
    if (strHtmlClasses) {
        if (strHtmlClasses !== cssClass) {
            const arrHtmlClasses = strHtmlClasses.split(' ');
            const cssClassIndex = arrHtmlClasses.findIndex(itemClass => itemClass === cssClass);

            if (cssClassIndex !== -1) {
                arrHtmlClasses.splice(cssClassIndex, 1);
                strHtmlClasses = '';
                arrHtmlClasses.forEach((cssClass, index) => {
                    strHtmlClasses += index < arrHtmlClasses.length - 1 ? `${cssClass} ` : cssClass;
                });
            }

            document.getElementsByTagName('html')[0].setAttribute('class', strHtmlClasses);
        } else {
            document.getElementsByTagName('html')[0].classList.remove(cssClass);
        }
    }
}

export function addElementClass(element, cssClass) {
    let strHtmlClasses = element.getAttribute('class');

    if (!strHtmlClasses) {
        strHtmlClasses = cssClass;
    } else {
        const arrHtmlClasses = strHtmlClasses.split(' ');
        const cssClassIndex = arrHtmlClasses.findIndex(itemClass => itemClass === cssClass);
        if (cssClassIndex === -1) {
            strHtmlClasses = strHtmlClasses + ' ' + cssClass;
        } else {
            return;
        }
    }

    element.setAttribute('class', strHtmlClasses);
}

export function removeElementClass(element, cssClass) {
    let strHtmlClasses = element.getAttribute('class');
    if (strHtmlClasses) {
        if (strHtmlClasses !== cssClass) {
            const arrHtmlClasses = strHtmlClasses.split(' ');
            const cssClassIndex = arrHtmlClasses.findIndex(itemClass => itemClass === cssClass);

            if (cssClassIndex !== -1) {
                arrHtmlClasses.splice(cssClassIndex, 1);
                strHtmlClasses = '';
                arrHtmlClasses.forEach((cssClass, index) => {
                    strHtmlClasses += index < arrHtmlClasses.length - 1 ? `${cssClass} ` : cssClass;
                });
            }

            element.setAttribute('class', strHtmlClasses);
        } else {
            element.classList.remove(cssClass);
        }
    }
}

// Scroll page to first class appearing, in most cases should be called inside $nextTick
export function scrollToClass(cssClass) {
    const firstElement = document.getElementsByClassName(cssClass).item(0);
    if (firstElement) {
        firstElement.scrollIntoView();
    }
}

export function scrollToId(cssId) {
    const element = document.getElementById(cssId);
    if (element) {
        element.scrollIntoView();
    }
}

// Smooth scroll page to first class appearing, in most cases should be called inside $nextTick
export function smoothScrollToClass(cssClass, toolbarOffset) {
    function scrollToFirstElement(offsetArg) {
        const firstElement = document.getElementsByClassName(cssClass).item(0);
        if (firstElement) {
            const bodyRect = document.body.getBoundingClientRect();
            const elemRect = firstElement.getBoundingClientRect();
            const offset = (bodyRect.top - elemRect.top) * (-1) - offsetArg;
            window.scroll({ top: offset, left: 0, behavior: 'smooth' });
        }
    }

    if (toolbarOffset) {
        switch (typeof toolbarOffset) {
            case 'boolean': {
                // Toolbar height is 100px
                scrollToFirstElement(100);
                break;
            }
            case 'number': {
                scrollToFirstElement(toolbarOffset);
                break;
            }
        }
    } else {
        document.querySelector(`.${cssClass}`).scrollIntoView({ behavior: 'smooth' });
    }
}

export function smoothScrollToId(cssId, toolbarOffset) {
    function scrollToElement(offsetArg) {
        const element = document.getElementById(cssId);
        if (element) {
            const bodyRect = document.body.getBoundingClientRect();
            const elemRect = element.getBoundingClientRect();
            const offset = (bodyRect.top - elemRect.top) * (-1) - offsetArg;
            window.scroll({ top: offset, left: 0, behavior: 'smooth' });
        }
    }

    if (toolbarOffset) {
        switch (typeof toolbarOffset) {
            case 'boolean': {
                // Toolbar height is 100px
                scrollToElement(100);
                break;
            }
            case 'number': {
                scrollToElement(toolbarOffset);
                break;
            }
        }
    } else {
        document.querySelector(`#${cssId}`).scrollIntoView({ behavior: 'smooth' });
    }
}

export function smoothScrollToTop() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
}

export function windowOpen(url, type) {
    if (url && type) {
        return window.open(url.match(/^http[s]?:\/\//i) ? url : `http://${url}`, type);
    }
}
