<template>
    <v-app>
        <nuxt/>
        <!-- Cookies confirm-->
        <cookies-settings/>
    </v-app>
</template>

<script>
    import CookiesSettings from '../components/CookiesSettings.vue';

    export default {
        name: 'Default',
        components: { CookiesSettings }
    };
</script>

<style lang="scss" scoped>
    .v-application {
        display: block!important;
    }

</style>