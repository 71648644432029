import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _80e94d30 = () => interopDefault(import('../pages/aktivity/index.vue' /* webpackChunkName: "pages/aktivity/index" */))
const _bb4184a2 = () => interopDefault(import('../pages/bannery.vue' /* webpackChunkName: "pages/bannery" */))
const _609e7448 = () => interopDefault(import('../pages/diplomy.vue' /* webpackChunkName: "pages/diplomy" */))
const _99fa0bd2 = () => interopDefault(import('../pages/lekari/index.vue' /* webpackChunkName: "pages/lekari/index" */))
const _6ed0fc02 = () => interopDefault(import('../pages/notifikace.vue' /* webpackChunkName: "pages/notifikace" */))
const _2c9b694c = () => interopDefault(import('../pages/oauth2/index.vue' /* webpackChunkName: "pages/oauth2/index" */))
const _b4c9fce8 = () => interopDefault(import('../pages/objednavky/index.vue' /* webpackChunkName: "pages/objednavky/index" */))
const _72f46fcb = () => interopDefault(import('../pages/obnova-hesla.vue' /* webpackChunkName: "pages/obnova-hesla" */))
const _f9a054ce = () => interopDefault(import('../pages/okresni-sdruzeni/index.vue' /* webpackChunkName: "pages/okresni-sdruzeni/index" */))
const _5c0e16d5 = () => interopDefault(import('../pages/placene-sluzby/index.vue' /* webpackChunkName: "pages/placene-sluzby/index" */))
const _7bc9989c = () => interopDefault(import('../pages/portaly/index.vue' /* webpackChunkName: "pages/portaly/index" */))
const _356c80e8 = () => interopDefault(import('../pages/registratori/index.vue' /* webpackChunkName: "pages/registratori/index" */))
const _0554eca7 = () => interopDefault(import('../pages/reporty.vue' /* webpackChunkName: "pages/reporty" */))
const _3ad5e20f = () => interopDefault(import('../pages/videa-prenosy/index.vue' /* webpackChunkName: "pages/videa-prenosy/index" */))
const _18311c4a = () => interopDefault(import('../pages/zadosti/index.vue' /* webpackChunkName: "pages/zadosti/index" */))
const _27ed631a = () => interopDefault(import('../pages/zadosti-lekaru/index.vue' /* webpackChunkName: "pages/zadosti-lekaru/index" */))
const _cdb07992 = () => interopDefault(import('../pages/admin/diagnostika/index.vue' /* webpackChunkName: "pages/admin/diagnostika/index" */))
const _cad65e24 = () => interopDefault(import('../pages/admin/emaily/index.vue' /* webpackChunkName: "pages/admin/emaily/index" */))
const _12c99730 = () => interopDefault(import('../pages/admin/lokalizace/index.vue' /* webpackChunkName: "pages/admin/lokalizace/index" */))
const _799e5e06 = () => interopDefault(import('../pages/aktivity/nova.vue' /* webpackChunkName: "pages/aktivity/nova" */))
const _29655dd9 = () => interopDefault(import('../pages/clk/casto-kladene-otazky.vue' /* webpackChunkName: "pages/clk/casto-kladene-otazky" */))
const _ac21a13e = () => interopDefault(import('../pages/clk/manual.vue' /* webpackChunkName: "pages/clk/manual" */))
const _82653122 = () => interopDefault(import('../pages/clk/nastaveni/index.vue' /* webpackChunkName: "pages/clk/nastaveni/index" */))
const _9ed82250 = () => interopDefault(import('../pages/lekar/casto-kladene-otazky.vue' /* webpackChunkName: "pages/lekar/casto-kladene-otazky" */))
const _0ab44ac0 = () => interopDefault(import('../pages/lekar/manual.vue' /* webpackChunkName: "pages/lekar/manual" */))
const _501e60e0 = () => interopDefault(import('../pages/lekar/moje-aktivity/index.vue' /* webpackChunkName: "pages/lekar/moje-aktivity/index" */))
const _0779f310 = () => interopDefault(import('../pages/lekar/nastaveni/index.vue' /* webpackChunkName: "pages/lekar/nastaveni/index" */))
const _299b262d = () => interopDefault(import('../pages/lekar/nova-zadost/index.vue' /* webpackChunkName: "pages/lekar/nova-zadost/index" */))
const _5b6a041b = () => interopDefault(import('../pages/lekari/pridat.vue' /* webpackChunkName: "pages/lekari/pridat" */))
const _d94917b4 = () => interopDefault(import('../pages/oauth2/authorize/index.vue' /* webpackChunkName: "pages/oauth2/authorize/index" */))
const _1379e73a = () => interopDefault(import('../pages/oauth2/login.vue' /* webpackChunkName: "pages/oauth2/login" */))
const _65affa0f = () => interopDefault(import('../pages/okresni-sdruzeni/manual.vue' /* webpackChunkName: "pages/okresni-sdruzeni/manual" */))
const _383ac081 = () => interopDefault(import('../pages/okresni-sdruzeni/nastaveni/index.vue' /* webpackChunkName: "pages/okresni-sdruzeni/nastaveni/index" */))
const _18af0258 = () => interopDefault(import('../pages/placene-sluzby/reklama-na-aktivitach/index.vue' /* webpackChunkName: "pages/placene-sluzby/reklama-na-aktivitach/index" */))
const _38a7a9f9 = () => interopDefault(import('../pages/placene-sluzby/reklamni-plochy/index.vue' /* webpackChunkName: "pages/placene-sluzby/reklamni-plochy/index" */))
const _e541b27e = () => interopDefault(import('../pages/placene-sluzby/topovat-aktivitu/index.vue' /* webpackChunkName: "pages/placene-sluzby/topovat-aktivitu/index" */))
const _4c44b299 = () => interopDefault(import('../pages/portaly/cmc.vue' /* webpackChunkName: "pages/portaly/cmc" */))
const _4f96764d = () => interopDefault(import('../pages/portaly/registrar.vue' /* webpackChunkName: "pages/portaly/registrar" */))
const _3252dd78 = () => interopDefault(import('../pages/pruzkumy/covid-19.vue' /* webpackChunkName: "pages/pruzkumy/covid-19" */))
const _1db81a89 = () => interopDefault(import('../pages/registrace/lekar.vue' /* webpackChunkName: "pages/registrace/lekar" */))
const _132cd1d8 = () => interopDefault(import('../pages/registrace/registrator.vue' /* webpackChunkName: "pages/registrace/registrator" */))
const _2c00d82a = () => interopDefault(import('../pages/registrace/zaslat-odkaz-k-registraci.vue' /* webpackChunkName: "pages/registrace/zaslat-odkaz-k-registraci" */))
const _7cfcb3e9 = () => interopDefault(import('../pages/registrator/casto-kladene-otazky.vue' /* webpackChunkName: "pages/registrator/casto-kladene-otazky" */))
const _7d0f6171 = () => interopDefault(import('../pages/registrator/manual.vue' /* webpackChunkName: "pages/registrator/manual" */))
const _0c5c875f = () => interopDefault(import('../pages/registrator/nastaveni/index.vue' /* webpackChunkName: "pages/registrator/nastaveni/index" */))
const _61d62788 = () => interopDefault(import('../pages/registrator/schvaleni-registrace.vue' /* webpackChunkName: "pages/registrator/schvaleni-registrace" */))
const _8ee70ac4 = () => interopDefault(import('../pages/registratori/novy.vue' /* webpackChunkName: "pages/registratori/novy" */))
const _e1224ed2 = () => interopDefault(import('../pages/videa-prenosy/novy.vue' /* webpackChunkName: "pages/videa-prenosy/novy" */))
const _192c6702 = () => interopDefault(import('../pages/zadosti/cmc.vue' /* webpackChunkName: "pages/zadosti/cmc" */))
const _048b8a34 = () => interopDefault(import('../pages/zadosti/doctor.vue' /* webpackChunkName: "pages/zadosti/doctor" */))
const _6b51cb1a = () => interopDefault(import('../pages/zadosti/registrarDA.vue' /* webpackChunkName: "pages/zadosti/registrarDA" */))
const _52b32356 = () => interopDefault(import('../pages/lekar/nastaveni/oauth.vue' /* webpackChunkName: "pages/lekar/nastaveni/oauth" */))
const _7c1bb6ee = () => interopDefault(import('../pages/lekar/nastaveni/profil.vue' /* webpackChunkName: "pages/lekar/nastaveni/profil" */))
const _3e0d43f5 = () => interopDefault(import('../pages/placene-sluzby/partneri/novy.vue' /* webpackChunkName: "pages/placene-sluzby/partneri/novy" */))
const _138bac7f = () => interopDefault(import('../pages/registrator/nastaveni/profil.vue' /* webpackChunkName: "pages/registrator/nastaveni/profil" */))
const _27a280fc = () => interopDefault(import('../pages/videa-prenosy/prenosy/novy.vue' /* webpackChunkName: "pages/videa-prenosy/prenosy/novy" */))
const _1f5491c8 = () => interopDefault(import('../pages/zadosti/aktivity/cmc.vue' /* webpackChunkName: "pages/zadosti/aktivity/cmc" */))
const _0fa31f39 = () => interopDefault(import('../pages/zadosti/aktivity/registrarDA.vue' /* webpackChunkName: "pages/zadosti/aktivity/registrarDA" */))
const _f6dee9a2 = () => interopDefault(import('../pages/zadosti/diplomy/CMCAdminDA.vue' /* webpackChunkName: "pages/zadosti/diplomy/CMCAdminDA" */))
const _3a12e0ca = () => interopDefault(import('../pages/zadosti/diplomy/doctor.vue' /* webpackChunkName: "pages/zadosti/diplomy/doctor" */))
const _48e86f7e = () => interopDefault(import('../pages/zadosti/kredity/CMCAdminDA.vue' /* webpackChunkName: "pages/zadosti/kredity/CMCAdminDA" */))
const _59a1b4a6 = () => interopDefault(import('../pages/zadosti/kredity/doctor.vue' /* webpackChunkName: "pages/zadosti/kredity/doctor" */))
const _f295e05c = () => interopDefault(import('../pages/zadosti/kredity/novy.vue' /* webpackChunkName: "pages/zadosti/kredity/novy" */))
const _556a0b80 = () => interopDefault(import('../pages/zadosti/portaly/cmc.vue' /* webpackChunkName: "pages/zadosti/portaly/cmc" */))
const _5cbd0a8d = () => interopDefault(import('../pages/zadosti/portaly/novy.vue' /* webpackChunkName: "pages/zadosti/portaly/novy" */))
const _091945f4 = () => interopDefault(import('../pages/zadosti/portaly/registrar.vue' /* webpackChunkName: "pages/zadosti/portaly/registrar" */))
const _a8ca65ce = () => interopDefault(import('../pages/placene-sluzby/partneri/_id.vue' /* webpackChunkName: "pages/placene-sluzby/partneri/_id" */))
const _576c8a80 = () => interopDefault(import('../pages/placene-sluzby/reklama-na-aktivitach/_id.vue' /* webpackChunkName: "pages/placene-sluzby/reklama-na-aktivitach/_id" */))
const _d04c52be = () => interopDefault(import('../pages/placene-sluzby/reklamni-plochy/_id.vue' /* webpackChunkName: "pages/placene-sluzby/reklamni-plochy/_id" */))
const _0c59e569 = () => interopDefault(import('../pages/placene-sluzby/topovat-aktivitu/_id.vue' /* webpackChunkName: "pages/placene-sluzby/topovat-aktivitu/_id" */))
const _ca1fa796 = () => interopDefault(import('../pages/videa-prenosy/prenosy/_id/index.vue' /* webpackChunkName: "pages/videa-prenosy/prenosy/_id/index" */))
const _44f659c9 = () => interopDefault(import('../pages/zadosti/aktivity/_id.vue' /* webpackChunkName: "pages/zadosti/aktivity/_id" */))
const _106fc324 = () => interopDefault(import('../pages/zadosti/diplomy/_id.vue' /* webpackChunkName: "pages/zadosti/diplomy/_id" */))
const _110b919c = () => interopDefault(import('../pages/zadosti/kredity/_id.vue' /* webpackChunkName: "pages/zadosti/kredity/_id" */))
const _7b0bd381 = () => interopDefault(import('../pages/zadosti/portaly/_id.vue' /* webpackChunkName: "pages/zadosti/portaly/_id" */))
const _31d2866e = () => interopDefault(import('../pages/zadosti/registratori/_id.vue' /* webpackChunkName: "pages/zadosti/registratori/_id" */))
const _157127fa = () => interopDefault(import('../pages/videa-prenosy/prenosy/_id/nahled.vue' /* webpackChunkName: "pages/videa-prenosy/prenosy/_id/nahled" */))
const _64c98053 = () => interopDefault(import('../pages/aktivity/_id/index.vue' /* webpackChunkName: "pages/aktivity/_id/index" */))
const _c5d99cfc = () => interopDefault(import('../pages/lekari/_id/index.vue' /* webpackChunkName: "pages/lekari/_id/index" */))
const _4a581218 = () => interopDefault(import('../pages/objednavky/_id.vue' /* webpackChunkName: "pages/objednavky/_id" */))
const _71e67a9a = () => interopDefault(import('../pages/portaly/_id.vue' /* webpackChunkName: "pages/portaly/_id" */))
const _4c759d50 = () => interopDefault(import('../pages/registratori/_id.vue' /* webpackChunkName: "pages/registratori/_id" */))
const _9e7d450c = () => interopDefault(import('../pages/videa-prenosy/_id/index.vue' /* webpackChunkName: "pages/videa-prenosy/_id/index" */))
const _078ceead = () => interopDefault(import('../pages/aktivity/_id/akreditovat.vue' /* webpackChunkName: "pages/aktivity/_id/akreditovat" */))
const _d40f1c98 = () => interopDefault(import('../pages/aktivity/_id/owner.vue' /* webpackChunkName: "pages/aktivity/_id/owner" */))
const _7c0267b8 = () => interopDefault(import('../pages/aktivity/_id/public.vue' /* webpackChunkName: "pages/aktivity/_id/public" */))
const _0a3e9a8a = () => interopDefault(import('../pages/lekari/_id/pridelit-kredity.vue' /* webpackChunkName: "pages/lekari/_id/pridelit-kredity" */))
const _5a4975f8 = () => interopDefault(import('../pages/lekari/_id/vystavit-diplom.vue' /* webpackChunkName: "pages/lekari/_id/vystavit-diplom" */))
const _3fec5002 = () => interopDefault(import('../pages/videa-prenosy/_id/upravit.vue' /* webpackChunkName: "pages/videa-prenosy/_id/upravit" */))
const _9da0c098 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktivity",
    component: _80e94d30,
    name: "aktivity"
  }, {
    path: "/bannery",
    component: _bb4184a2,
    name: "bannery"
  }, {
    path: "/diplomy",
    component: _609e7448,
    name: "diplomy"
  }, {
    path: "/lekari",
    component: _99fa0bd2,
    name: "lekari"
  }, {
    path: "/notifikace",
    component: _6ed0fc02,
    name: "notifikace"
  }, {
    path: "/oauth2",
    component: _2c9b694c,
    name: "oauth2"
  }, {
    path: "/objednavky",
    component: _b4c9fce8,
    name: "objednavky"
  }, {
    path: "/obnova-hesla",
    component: _72f46fcb,
    name: "obnova-hesla"
  }, {
    path: "/okresni-sdruzeni",
    component: _f9a054ce,
    name: "okresni-sdruzeni"
  }, {
    path: "/placene-sluzby",
    component: _5c0e16d5,
    name: "placene-sluzby"
  }, {
    path: "/portaly",
    component: _7bc9989c,
    name: "portaly"
  }, {
    path: "/registratori",
    component: _356c80e8,
    name: "registratori"
  }, {
    path: "/reporty",
    component: _0554eca7,
    name: "reporty"
  }, {
    path: "/videa-prenosy",
    component: _3ad5e20f,
    name: "videa-prenosy"
  }, {
    path: "/zadosti",
    component: _18311c4a,
    name: "zadosti"
  }, {
    path: "/zadosti-lekaru",
    component: _27ed631a,
    name: "zadosti-lekaru"
  }, {
    path: "/admin/diagnostika",
    component: _cdb07992,
    name: "admin-diagnostika"
  }, {
    path: "/admin/emaily",
    component: _cad65e24,
    name: "admin-emaily"
  }, {
    path: "/admin/lokalizace",
    component: _12c99730,
    name: "admin-lokalizace"
  }, {
    path: "/aktivity/nova",
    component: _799e5e06,
    name: "aktivity-nova"
  }, {
    path: "/clk/casto-kladene-otazky",
    component: _29655dd9,
    name: "clk-casto-kladene-otazky"
  }, {
    path: "/clk/manual",
    component: _ac21a13e,
    name: "clk-manual"
  }, {
    path: "/clk/nastaveni",
    component: _82653122,
    name: "clk-nastaveni"
  }, {
    path: "/lekar/casto-kladene-otazky",
    component: _9ed82250,
    name: "lekar-casto-kladene-otazky"
  }, {
    path: "/lekar/manual",
    component: _0ab44ac0,
    name: "lekar-manual"
  }, {
    path: "/lekar/moje-aktivity",
    component: _501e60e0,
    name: "lekar-moje-aktivity"
  }, {
    path: "/lekar/nastaveni",
    component: _0779f310,
    name: "lekar-nastaveni"
  }, {
    path: "/lekar/nova-zadost",
    component: _299b262d,
    name: "lekar-nova-zadost"
  }, {
    path: "/lekari/pridat",
    component: _5b6a041b,
    name: "lekari-pridat"
  }, {
    path: "/oauth2/authorize",
    component: _d94917b4,
    name: "oauth2-authorize"
  }, {
    path: "/oauth2/login",
    component: _1379e73a,
    name: "oauth2-login"
  }, {
    path: "/okresni-sdruzeni/manual",
    component: _65affa0f,
    name: "okresni-sdruzeni-manual"
  }, {
    path: "/okresni-sdruzeni/nastaveni",
    component: _383ac081,
    name: "okresni-sdruzeni-nastaveni"
  }, {
    path: "/placene-sluzby/reklama-na-aktivitach",
    component: _18af0258,
    name: "placene-sluzby-reklama-na-aktivitach"
  }, {
    path: "/placene-sluzby/reklamni-plochy",
    component: _38a7a9f9,
    name: "placene-sluzby-reklamni-plochy"
  }, {
    path: "/placene-sluzby/topovat-aktivitu",
    component: _e541b27e,
    name: "placene-sluzby-topovat-aktivitu"
  }, {
    path: "/portaly/cmc",
    component: _4c44b299,
    name: "portaly-cmc"
  }, {
    path: "/portaly/registrar",
    component: _4f96764d,
    name: "portaly-registrar"
  }, {
    path: "/pruzkumy/covid-19",
    component: _3252dd78,
    name: "pruzkumy-covid-19"
  }, {
    path: "/registrace/lekar",
    component: _1db81a89,
    name: "registrace-lekar"
  }, {
    path: "/registrace/registrator",
    component: _132cd1d8,
    name: "registrace-registrator"
  }, {
    path: "/registrace/zaslat-odkaz-k-registraci",
    component: _2c00d82a,
    name: "registrace-zaslat-odkaz-k-registraci"
  }, {
    path: "/registrator/casto-kladene-otazky",
    component: _7cfcb3e9,
    name: "registrator-casto-kladene-otazky"
  }, {
    path: "/registrator/manual",
    component: _7d0f6171,
    name: "registrator-manual"
  }, {
    path: "/registrator/nastaveni",
    component: _0c5c875f,
    name: "registrator-nastaveni"
  }, {
    path: "/registrator/schvaleni-registrace",
    component: _61d62788,
    name: "registrator-schvaleni-registrace"
  }, {
    path: "/registratori/novy",
    component: _8ee70ac4,
    name: "registratori-novy"
  }, {
    path: "/videa-prenosy/novy",
    component: _e1224ed2,
    name: "videa-prenosy-novy"
  }, {
    path: "/zadosti/cmc",
    component: _192c6702,
    name: "zadosti-cmc"
  }, {
    path: "/zadosti/doctor",
    component: _048b8a34,
    name: "zadosti-doctor"
  }, {
    path: "/zadosti/registrarDA",
    component: _6b51cb1a,
    name: "zadosti-registrarDA"
  }, {
    path: "/lekar/nastaveni/oauth",
    component: _52b32356,
    name: "lekar-nastaveni-oauth"
  }, {
    path: "/lekar/nastaveni/profil",
    component: _7c1bb6ee,
    name: "lekar-nastaveni-profil"
  }, {
    path: "/placene-sluzby/partneri/novy",
    component: _3e0d43f5,
    name: "placene-sluzby-partneri-novy"
  }, {
    path: "/registrator/nastaveni/profil",
    component: _138bac7f,
    name: "registrator-nastaveni-profil"
  }, {
    path: "/videa-prenosy/prenosy/novy",
    component: _27a280fc,
    name: "videa-prenosy-prenosy-novy"
  }, {
    path: "/zadosti/aktivity/cmc",
    component: _1f5491c8,
    name: "zadosti-aktivity-cmc"
  }, {
    path: "/zadosti/aktivity/registrarDA",
    component: _0fa31f39,
    name: "zadosti-aktivity-registrarDA"
  }, {
    path: "/zadosti/diplomy/CMCAdminDA",
    component: _f6dee9a2,
    name: "zadosti-diplomy-CMCAdminDA"
  }, {
    path: "/zadosti/diplomy/doctor",
    component: _3a12e0ca,
    name: "zadosti-diplomy-doctor"
  }, {
    path: "/zadosti/kredity/CMCAdminDA",
    component: _48e86f7e,
    name: "zadosti-kredity-CMCAdminDA"
  }, {
    path: "/zadosti/kredity/doctor",
    component: _59a1b4a6,
    name: "zadosti-kredity-doctor"
  }, {
    path: "/zadosti/kredity/novy",
    component: _f295e05c,
    name: "zadosti-kredity-novy"
  }, {
    path: "/zadosti/portaly/cmc",
    component: _556a0b80,
    name: "zadosti-portaly-cmc"
  }, {
    path: "/zadosti/portaly/novy",
    component: _5cbd0a8d,
    name: "zadosti-portaly-novy"
  }, {
    path: "/zadosti/portaly/registrar",
    component: _091945f4,
    name: "zadosti-portaly-registrar"
  }, {
    path: "/placene-sluzby/partneri/:id",
    component: _a8ca65ce,
    name: "placene-sluzby-partneri-id"
  }, {
    path: "/placene-sluzby/reklama-na-aktivitach/:id",
    component: _576c8a80,
    name: "placene-sluzby-reklama-na-aktivitach-id"
  }, {
    path: "/placene-sluzby/reklamni-plochy/:id",
    component: _d04c52be,
    name: "placene-sluzby-reklamni-plochy-id"
  }, {
    path: "/placene-sluzby/topovat-aktivitu/:id",
    component: _0c59e569,
    name: "placene-sluzby-topovat-aktivitu-id"
  }, {
    path: "/videa-prenosy/prenosy/:id",
    component: _ca1fa796,
    name: "videa-prenosy-prenosy-id"
  }, {
    path: "/zadosti/aktivity/:id?",
    component: _44f659c9,
    name: "zadosti-aktivity-id"
  }, {
    path: "/zadosti/diplomy/:id?",
    component: _106fc324,
    name: "zadosti-diplomy-id"
  }, {
    path: "/zadosti/kredity/:id?",
    component: _110b919c,
    name: "zadosti-kredity-id"
  }, {
    path: "/zadosti/portaly/:id?",
    component: _7b0bd381,
    name: "zadosti-portaly-id"
  }, {
    path: "/zadosti/registratori/:id?",
    component: _31d2866e,
    name: "zadosti-registratori-id"
  }, {
    path: "/videa-prenosy/prenosy/:id/nahled",
    component: _157127fa,
    name: "videa-prenosy-prenosy-id-nahled"
  }, {
    path: "/aktivity/:id",
    component: _64c98053,
    name: "aktivity-id"
  }, {
    path: "/lekari/:id",
    component: _c5d99cfc,
    name: "lekari-id"
  }, {
    path: "/objednavky/:id",
    component: _4a581218,
    name: "objednavky-id"
  }, {
    path: "/portaly/:id",
    component: _71e67a9a,
    name: "portaly-id"
  }, {
    path: "/registratori/:id",
    component: _4c759d50,
    name: "registratori-id"
  }, {
    path: "/videa-prenosy/:id?",
    component: _9e7d450c,
    name: "videa-prenosy-id"
  }, {
    path: "/aktivity/:id/akreditovat",
    component: _078ceead,
    name: "aktivity-id-akreditovat"
  }, {
    path: "/aktivity/:id/owner",
    component: _d40f1c98,
    name: "aktivity-id-owner"
  }, {
    path: "/aktivity/:id/public",
    component: _7c0267b8,
    name: "aktivity-id-public"
  }, {
    path: "/lekari/:id/pridelit-kredity",
    component: _0a3e9a8a,
    name: "lekari-id-pridelit-kredity"
  }, {
    path: "/lekari/:id/vystavit-diplom",
    component: _5a4975f8,
    name: "lekari-id-vystavit-diplom"
  }, {
    path: "/videa-prenosy/:id?/upravit",
    component: _3fec5002,
    name: "videa-prenosy-id-upravit"
  }, {
    path: "/",
    component: _9da0c098,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
