import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import cs from 'vuetify/es5/locale/cs';

Vue.use(Vuetify);

export const basicColors = {
    primary: '#40BCED',
    secondary: '#EBF8FD',
    green: '#22ABAB',
    'info-primary': '#407db3',
    'warning-primary': '#e7a84e',
    'success-primary': '#4fb430'
};

export const plotColors = {
    'red-plot': '#ff6362',
    'green-plot': '#5bc2ba',
    'yellow-plot': '#ffd400',
    'navy-plot': '#627aac',
    'orange-plot': '#f26522',
    'gray-ploat': '#a9a9a9',
    'pruple-plot': '#bc5090',
    'lavender-plot': '#dcbeff',
    'brown-plot': '#9A6324',
    'pink-plot': '#fabed4',
    'maroon-plot': '#800000',
    'apricot-plot': '#ffd8b1',
    'olive-plot': '#808000',
    'magenta-plot': '#d70072',
    'mustard-plot': '#d8af45',
    'cabbage-plot': '#609181',
    'cyan-plot': '#42d4f4',
    'blue-gray-plot': '#5f7d8c',
    'mint-plot': '#aaffc3',
    'blue': '#4363d8'
};

export default (ctx) => {
    const vuetify = new Vuetify({
        theme: { themes: { light: {
            ...basicColors,
            ...plotColors
        } } },
        lang: {
            locales: { cs },
            current: 'cs'
        }
    });
    ctx.app.vuetify = vuetify;
    ctx.$vuetify = vuetify.framework;
};
