<template>
    <notifications :group="group" class="custom-notification">
        <template slot="body" slot-scope="props">
            <div
                class="notification-body"
                :class="props.item.type"
                @click="props.close"
            >
                <div class="icon">
                    <i v-if="props.item.type === 'success'" class="fal fa-check-circle"/>
                    <i v-if="props.item.type === 'error'" class="fal fa-times-circle"/>
                    <i v-if="props.item.type === 'maintenance'" class="fal fa-tools"/>
                </div>
                <div>
                    <div class="title">
                        {{ props.item.title }}
                    </div>
                    <div class="text">
                        {{ props.item.text }}
                    </div>
                </div>
                <div
                    v-if="props.item.type === 'maintenance'"
                    class="action"
                    @click="refreshPage"
                >
                    <div class="action-button">
                        <i class="far fa-sync-alt"/>
                        <div class="button-label"> <small>Obnovit</small></div>
                    </div>
                </div>
            </div>
        </template>
    </notifications>
</template>

<script>
    export default {
        name: 'NotificationMessages',
        props: {
            group: {
                type: String,
                default: 'app'
            }
        },
        methods: {
            refreshPage() {
                window.location.reload();
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import '../assets/scss/variables';
    .custom-notification {
        max-width: 50rem;
        width: auto!important;

        .notification-body {
            display: flex;
            align-items: center;
            font-family: Roboto;
            padding: 0.5rem 1rem;
            margin: 1rem;
            margin-bottom: 0.5rem;
            border-radius: 0.5rem;

            &.success {
                background-color: color('succces-secondary')!important;
                color: color('succces-primary')!important;
                border: 1px solid color('succces-primary')!important;
            }
            &.error {
                background-color: color('error-secondary')!important;
                color: color('error-primary')!important;
                border: 1px solid color('error-primary')!important;
            }
            &.maintenance {
                background-color: color('info-secondary')!important;
                color: color('info-primary')!important;
                border: 1px solid color('info-primary')!important;
            }

            .icon {
                margin-right: 1rem;
                font-size: 1.5rem;
            }

            .title {
                font-size: 1.2rem;
                font-weight: 500;
            }

            .text {
                font-size: 1.2rem;
            }

            .action {
                margin-left: 1rem;
                padding-left: 0.5rem;
                text-align: center;
                border-left: 1px solid #407db367;
                cursor: pointer;
                font-size: 1.2rem;

                .action-button {
                    margin-right: -0.5rem;
                    padding: 0.5rem;
                    border-radius: 0.2rem;

                    &:hover {
                        background: color('light-blue');
                    }
                }
            }
        }
}
</style>
