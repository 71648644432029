Storage.prototype.setObject = function(key, value) {
    this.setItem(key, JSON.stringify(value));
};

Storage.prototype.getObject = function(key) {
    const value = this.getItem(key);
    return value && JSON.parse(value);
};

// Local storage setters
export function setAuthInfo(authInfo) {
    if (authInfo) {
        if (authInfo.user) {
            if (authInfo.role) {
                localStorage.setObject('userInfo', {
                    ...authInfo.user,
                    role: authInfo.role
                });
            } else {
                localStorage.setObject('userInfo', authInfo.user);
            }
        }
        if (authInfo.auth) {
            if (authInfo.auth.access_token && authInfo.auth.access_token_expires_at) {
                localStorage.setObject('accessTokenInfo', {
                    access_token: authInfo.auth.access_token,
                    access_token_expires_at: authInfo.auth.access_token_expires_at
                });
            }
        }
    }
}

export function setUserStatus(userStatus) { localStorage.setItem('userStatus', userStatus); }

export function logoutUser() { localStorage.setItem('logoutUser', 'logout'); }

export function setRouteBack(routeBack) { localStorage.setItem('routeBack', routeBack); }

export function setRefreshPage() { localStorage.setItem('refreshPage', 'true'); }

export function setShowAuthLoginModal(showAuthLoginModal) { localStorage.setItem('showAuthLoginModal', showAuthLoginModal); }

export function setUserInfo(userInfo) { localStorage.setObject('userInfo', userInfo); }

export function setCookiesConfirmed() { localStorage.setItem('cookiesConfirmed', true); }

export function setCookiesSettings(settings) {
    localStorage.setItem('cookiesSettings', JSON.stringify(settings));
}

// Local storage getters
export function getUserInfo() { return localStorage.getObject('userInfo'); }

export function getCookiesConfirmed() { return localStorage.getItem('cookiesConfirmed'); }

export function getCookiesSettings() {
    const settings = localStorage.getItem('cookiesSettings');
    return settings && JSON.parse(settings);
}

export function getUserRole() {
    const userInfo = localStorage.getObject('userInfo');
    if (userInfo) {
        if (userInfo.role) {
            return userInfo.role;
        }
    }
    return null;
}

export function getUserEmail() {
    const userInfo = localStorage.getObject('userInfo');
    if (userInfo) {
        if (userInfo.email) {
            return userInfo.email;
        }
    }
    return null;
}

export function isUserAuth() { return !!localStorage.getObject('userInfo'); }

export function getRefreshTokenInfo() { return localStorage.getObject('refreshTokenInfo'); }

export function getUserStatus() { return localStorage.getItem('userStatus'); }

export function getUserID() {
    const userInfo = localStorage.getObject('userInfo');
    if (userInfo) {
        if (userInfo.id) { return userInfo.id; }
    }
    return null;
}

export function getDAID() {
    const userInfo = localStorage.getObject('userInfo');
    if (userInfo) {
        if (userInfo.district_association_id) { return userInfo.district_association_id; }
    }
    return null;
}

export function getCmcID() {
    const userInfo = localStorage.getObject('userInfo');
    if (userInfo) {
        return userInfo.cmc_id ? userInfo.cmc_id : null;
    }
    return null;
}

export function getFullName() {
    const userInfo = localStorage.getObject('userInfo');
    if (userInfo) {
        return userInfo.full_name ? userInfo.full_name : null;
    }
    return null;
}

export function getDoctorsSpecialities() {
    const userInfo = localStorage.getObject('userInfo');
    if (userInfo) {
        return userInfo.specialities ? userInfo.specialities : null;
    }
    return null;
}

export function getDoctorsInterestSpecialities() {
    const userInfo = localStorage.getObject('userInfo');
    if (userInfo) {
        return userInfo.interest_specialities ? userInfo.interest_specialities : null;
    }
    return null;
}

export function getLogoutUser() { return localStorage.getItem('logoutUser'); }

export function getRouteBack() { return localStorage.getItem('routeBack'); }

export function getRefreshPage() { return localStorage.getItem('refreshPage'); }

export function getAccessToken() {
    const accessTokenInfo = localStorage.getObject('accessTokenInfo');
    if (accessTokenInfo) {
        if (accessTokenInfo.access_token) { return accessTokenInfo.access_token; }
    }
    return null;
}

export function getAccessTokenExpiresAt() {
    const accessTokenInfo = localStorage.getObject('accessTokenInfo');
    if (accessTokenInfo) {
        if (accessTokenInfo.access_token_expires_at) { return accessTokenInfo.access_token_expires_at; }
    }
    return null;
}

export function getTempUserId() { return localStorage.getItem('tempUserId'); }

export function getShowAuthLoginModal() { return localStorage.getItem('showAuthLoginModal'); }

// Local storage remove item
export function removeLogout() {
    localStorage.removeItem('logoutUser');
}

export function removeTempUserId() { localStorage.removeItem('tempUserId'); }

// Local storage generic functions
export function softLogout() {
    const userId = getUserID();
    localStorage.setItem('tempUserId', userId);
}

export function clearLocalStorage() {
    const cookiesConfirmed = getCookiesConfirmed();
    const cookiesSettings = getCookiesSettings();
    localStorage.clear();
    if (cookiesConfirmed) { localStorage.setItem('cookiesConfirmed', cookiesConfirmed); }
    if (cookiesSettings) { localStorage.setItem('cookiesSettings', JSON.stringify(cookiesSettings)); }
}
