<template>
    <collapse-transition>
        <div
            v-if="messages.length > 0"
            :class="type"
            class="error-messages-wrapper message"
        >
            <div class="message-icon-wrapper">
                <div class="message-icon"/>
            </div>
            <div>
                <div
                    v-for="(message, index) in messages"
                    :key="index"
                    class="message-text"
                >
                    {{ message }}
                </div>
            </div>
        </div>
    </collapse-transition>
</template>

<script>
    import { CollapseTransition } from 'vue2-transitions';

    export default {
        name: 'MessageBox',
        components: { CollapseTransition },
        props: {
            type: {
                type: String,
                default: 'info-message',
                validator: value => ['success-message', 'warning-message', 'info-message', 'error-message'].includes(value)
            },
            messages: {
                type: Array,
                required: true
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import '../assets/scss/variables';

    .message-text:not(:first-of-type) {
        margin-top: 1rem;
    }

    .message-icon {
        width: 1.5rem;
        height: 1.5rem;
        background-repeat: no-repeat
    }

    .error-messages-wrapper.message.info-message {
        background-color: color('info-secondary');
        border-color: color('info-primary');
        color: color('info-primary');

        .message-icon {
            background: url("/img/info-circle.svg");
        }
    }

    .error-messages-wrapper.message.success-message {
        background-color: color('succces-secondary');
        border-color: color('succces-primary');
        color: color('succces-primary');

        .message-icon {
            background: url("/img/check-circle.svg");
        }
    }

    .error-messages-wrapper.message.warning-message {
        background-color: color('warning-secondary');
        border-color: color('warning-primary');
        color: color('warning-primary');

        .message-icon {
            background: url("/img/exclamation-circle.svg");
        }
    }

    .error-messages-wrapper.message.error-message {
        background-color: color('error-secondary');
        border-color: color('error-primary');
        color: color('error-primary');

        .message-icon {
            background: url("/img/times-circle.svg");
        }
    }

    .error-messages-wrapper {
        border-radius: 0.5rem;
        text-align: left;
        display: flex;
        align-items: center;
    }

    .message-icon-wrapper {
        margin: 0 1rem 0 0.5rem;
    }
</style>