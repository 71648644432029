<template>
    <v-dialog
        :value="value"
        persistent
        width="500"
        @input="$emit('input', $event)"
    >
        <v-card>
            <v-card-title class="headline">
                <i class="fad fa-user-clock mr-4"/> {{ $t('c_common.c_titles.c_automaticLogOut') }}
            </v-card-title>
            <v-card-text>
                <form @submit.prevent="onFormSubmit">
                    {{ $t('c_common.c_modals.c_logOutModalText') }}
                    <message-box :messages="errors" type="error-message"/>
                    <base-field-input
                        v-model="login.credentials.email"
                        :validation="$v.login.credentials.email"
                        label="Email"
                        class="mt-6 mb-2"
                    />
                    <base-field-input
                        v-model="login.credentials.password"
                        :validation="$v.login.credentials.password"
                        :label="$t('c_common.c_labels.c_password')"
                        type="password"
                    />
                    <v-checkbox
                        v-model="login.stay_signed_in"
                        :label="$t('c_login.c_stayLogIn')"
                        class="mt-0"
                    />
                    <div class="button-wrapper">
                        <v-btn text @click="$_userLogout">{{ $t('c_common.c_buttons.c_logout') }}</v-btn>
                        <v-btn
                            :loading="loading"
                            type="submit"
                            color="primary"
                            class="secondary"
                            text
                        >
                            {{ $t('c_login.c_title') }}
                        </v-btn>
                    </div>
                </form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import BaseFieldInput from '~/components/BaseFieldInput';
    import MessageBox from '~/components/MessageBox';
    import { email, required } from '~/mixins/vuelidateMessagesMixin';
    import {
        removeLogout,
        setAuthInfo,
        setUserStatus,
        getTempUserId,
        removeTempUserId
    } from '~/utils/localStorageUtils';
    import menuMixin from '~/mixins/menuMixin';
    import tokenMixin from '~/mixins/tokenMixin';
    import { deepCopy } from '@/utils/arrayUtils';

    const defaultLogin = Object.freeze({
        credentials: {
            email: '',
            password: ''
        },
        stay_signed_in: false
    });

    export default {
        name: 'ModalSignIn',
        components: { BaseFieldInput, MessageBox },
        mixins: [menuMixin, tokenMixin],
        props: {
            value: {
                type: Boolean,
                required: true
            }
        },
        validations() {
            return {
                login: {
                    credentials: {
                        email: {
                            required: required(),
                            email: email()
                        },
                        password: {
                            required: required()
                        }
                    }
                }
            };
        },
        data() {
            return {
                loading: false,
                errors: [],
                login: deepCopy(defaultLogin)
            };
        },
        methods: {
            async onFormSubmit() {
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    try {
                        this.loading = true;
                        const authInfo = await this.$axios.$post('/auth', this.login);
                        removeLogout();
                        setAuthInfo(authInfo);
                        this.$axios.setToken(authInfo.auth.access_token, 'Bearer');
                        // In case registrar check status
                        if (authInfo.role === 'registrar') {
                            const fetchedRegistrar = await this.$axios.$get(`/registrars/${authInfo.user.id}`, {
                                params: { includeRequest: true }
                            });
                            setUserStatus(fetchedRegistrar.request.status);
                        }

                        // Close sign in modal
                        this.$store.commit('setShowAuthLoginModal', false);

                        const tempUserId = getTempUserId();
                        // eslint-disable-next-line
                        if (authInfo.user.id != tempUserId) {
                            const targetRoute = this.$getDefaultRoleRoute(authInfo.role);
                            const currentRoute = this.$route.name;
                            if (targetRoute !== currentRoute) {
                                await this.$router.push({ name: this.$getDefaultRoleRoute(authInfo.role) });
                                setTimeout(() => {
                                    location.reload();
                                }, 500);
                            } else {
                                location.reload();
                            }
                        }
                        removeTempUserId();
                        this.login = deepCopy(defaultLogin);
                    } catch (error) {
                        this.errors = [this.$t('c_notifications.c_errorBackendConnect')];
                        if (error.response) {
                            if (error.response.data) {
                                this.errors = error.response.data.errors.map(error => error.message);
                            }
                        }
                    } finally {
                        this.loading = false;
                    }
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
@import '././assets/scss/variables';

    .button-wrapper {
        display: flex;
        justify-content: flex-end;

        .v-btn:first-of-type {
            margin-right: 1rem;
        }
    }
    @media screen and (max-width: breakpoint('sm')) {
        .button-wrapper{
            flex-direction: column-reverse;

            .v-btn:first-of-type {
                margin-right: 0;
                margin-top: 1rem;
            }

        }

        .fa-user-clock {
            display: none;
        }
    }
</style>
