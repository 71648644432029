import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';

const prodHostname = 'vzdelavanilekaru.cz';

const prodId = 'GTM-MQCTPKZ';
const stageId = 'GTM-KFG2MQJ';

const isProd = window.location.hostname === prodHostname;

const id = isProd ? prodId : stageId;

Vue.use(VueGtm, {
  id,
  enabled: false
});
