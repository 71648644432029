export function parseLink(link) {
    /* eslint-disable */
    const mainDomaionExpression = /https?:\/\/([^.\/]+\.)*([^.\/]+\.[^.\/]+)(\/.+)?/g;
    /* eslint-enable */
    const match = mainDomaionExpression.exec(link);
    // Main domain will be always in second match group
    if (match) {
        if (match[2]) {
            return match[2];
        }
    }
    return link;
}

export function addProtocol(link) {
    if (typeof link === 'string') {
        if (!link.toLowerCase().startsWith('http') && !link.toLowerCase().startsWith('https')) {
            return 'http://' + link;
        }
    }
    return link;
}

export function parseVideoId(youtubeLink) {
    const urlEmbedIndex = youtubeLink.indexOf('embed');
    if (urlEmbedIndex !== -1) {
        const questionMarkIndex = youtubeLink.indexOf('?');
        const videoId = youtubeLink.substring(
            urlEmbedIndex + 'embed/'.length, questionMarkIndex !== -1 ? questionMarkIndex : youtubeLink.length
        );
        return videoId || null;
    }
}

export function addAutoplay(youtubeLink) {
    const urlAutoplayIndex = youtubeLink.indexOf('autoplay');
    if (urlAutoplayIndex === -1) {
        const questionMarkIndex = youtubeLink.indexOf('?');
        if (questionMarkIndex !== -1) {
            return youtubeLink + '&autoplay=true';
        } else {
            return youtubeLink + '?autoplay=true';
        }
    } else {
        return youtubeLink;
    }
}
