<template>
    <div id="not-found">
        <v-row>
            <v-col
                id="text-container"
                sm="6"
                cols="12"
            >
                <header>{{ title }}</header>
                <div class="main-body">
                    <p v-if="message">{{ message }}</p>
                    <div v-if="!hideBackButton" id="action">
                        <v-btn
                            color="primary"
                            text
                            class="mt-8"
                            @click="$emit('button-click')"
                        >
                            <v-icon left>mdi-arrow-left</v-icon>{{ backButtonText }}
                        </v-btn>
                    </div>
                </div>
            </v-col>
            <v-col>
                <img :src="imagePath">
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: 'ErrorPage',
        props: {
            title: {
                type: String,
                required: true
            },
            message: {
                type: String,
                default: null
            },
            imagePath: {
                type: String,
                required: true
            },
            backButtonText: {
                type: String,
                default: 'Zpět na úvodní stránku'
            },
            hideBackButton: {
                type: Boolean,
                default: false
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import '././assets/scss/variables';

    #not-found {
        max-width: 70rem;
        margin: auto;

        img {
            width: 100%;
        }

        #text-container {
            header {
                font-size: 2.2rem;
            }

            .main-body {
                font-size: 1.5rem;
                margin-top: 1rem;
            }

            @media (max-width: breakpoint('sm')) {
                header {
                    font-size: 1.5rem;
                }

                .main-body {
                    font-size: 1rem;
                }
            }
        }
    }
</style>