<template>
    <v-text-field
        :key="key"
        v-bind="$attrs"
        :value="value"
        :label="label"
        :error="isInvalid"
        :error-messages="isInvalid ? $getFieldError(validation) : ''"
        :class="{'required': isRequired}"
        :outlined="outlined"
        :dense="dense"
        @input="onInput"
        @blur="onBlur"
        @click:append="$emit('click-append')"
        @click:append-outer="$emit('click-append-outer')"
        @keyup.enter="$emit('keyup-enter')"
        @keyup.space="$emit('keyup-space')"
        @keyup.right="$emit('keyup-right')"
    >
        <slot
            v-for="(_, name) in $slots"
            :slot="name"
            :name="name"
        />
        <template v-if="info" v-slot:append>
            <v-icon>fal fa-info-circle</v-icon>
            <v-card>
                <v-card-text>{{ info }}</v-card-text>
            </v-card>
        </template>
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
    </v-text-field>
</template>

<script>
    import { vuelidateMessagesMixin } from '../mixins/vuelidateMessagesMixin';

    export default {
        name: 'BaseFieldInput',
        mixins: [vuelidateMessagesMixin],
        props: {
            label: {
                type: String,
                required: true
            },
            value: {
                required: true,
                validator: prop => typeof prop === 'string' || typeof prop === 'number' || prop === null
            },
            validation: {
                type: Object,
                default: null
            },
            outlined: {
                type: Boolean,
                default: true
            },
            dense: {
                type: Boolean,
                default: true
            },
            info: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                blur: false,
                key: 0
            };
        },
        computed: {
            isInvalid() {
                return this.validation && this.validation.$invalid && (this.blur || this.validation.$error);
            },
            isRequired() {
                if (this.validation) {
                    if (this.validation.$params.hasOwnProperty('required')) {
                        return this.validation.$params.required;
                    }
                }
                return false;
            }
        },
        methods: {
            onBlur(event) {
                this.blur = true;
                this.$emit('blur', event);
                if (this.$attrs.type === 'numeric') {
                    const modifiedInput = this.value.replace(/\D/g, '');
                    this.$emit('input', modifiedInput);
                    this.key++;
                }
            },
            onInput(event) {
                this.$emit('input', event);
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../assets/scss/variables";

    .v-input {
        ::v-deep .v-input__append-inner {
            cursor: pointer;

            .v-card {
                position: absolute;
                display: none;
                box-shadow: none;
                top: 2.8rem;
                right: 0;
                padding: 0.1rem;
                min-width: 10rem;
                background: gray;
                z-index: 10;
                opacity: 0.9;

                .v-card__text {
                    padding: 0.5rem;
                    color: white;
                }
            }

            &:hover {
                .v-card {
                    display: block;
                }
            }
        }
    }
</style>
