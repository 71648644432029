import { setShowAuthLoginModal } from '@/utils/localStorageUtils';

export const state = () => ({
  activeMenuItem: '',
  breadcrumbs: [],
  requestsCount: 0,
  notificationsCount: 0,
  totalNotificationsCount: 0,
  notificationDurationTime: 5000,
  appBarHeight: 100,
  serverErrors: [],
  routeBack: '',
  showLoginModal: false,
  beforeRouteName: '',
  userMenu: [],
  userDefaultActiveItem: {},
  logoutClick: false,
  showAuthLoginModal: false,
  showLogoutOverlay: false,
  doctorRequestsCount: 0,
  appBarMenu: [],
  doctorsStatistics: null,
  daRequestsCount: 0,
  updateProfileCount: 0,
  backendMaintenance: false,
  registrationServerErrors: [],
  registrarStatusFetched: false,
  showCookieModal: false,
  advertisementCookies: false
});

export const mutations = {
  logout(state) {
    state.activeMenuItem = '';
    state.breadcrumbs = [];
    state.requestsCount = 0;
    state.notificationsCount = 0;
    state.showLoginModal = false;
    state.routeBack = '';
    state.userMenu = [];
    state.userDefaultActiveItem = {};
    state.logoutClick = false;
    state.showAuthLoginModal = false;
    state.doctorRequestsCount = 0;
    state.appBarMenu = [];
    state.doctorsStatistics = null;
    state.daRequestsCount = 0;
    state.updateProfileCount = 0;
    state.backendMaintenance = false;
    state.registrarStatusFetched = false;
  },
  setMenuItem(state, menuItem) {
    state.activeMenuItem = menuItem;
  },
  setBreadcrumbItem(state, breadcrumb) {
    state.breadcrumbs.push(breadcrumb);
  },
  eraseBreadcrumbs(state) {
    state.breadcrumbs = [];
  },
  setRequestsCount(state, requestsCount) {
    state.requestsCount = requestsCount;
  },
  setNotificationsCount(state, notificationsCount) {
    state.notificationsCount = notificationsCount;
  },
  setTotalNotificationsCount(state, notificationsCount) {
    state.totalNotificationsCount = notificationsCount;
  },
  setServerErrors(state, errors) {
    state.serverErrors = errors;
  },
  clearServerErrors(state) {
    state.serverErrors = [];
  },
  setShowLoginModal(state, showLoginModal) {
    state.showLoginModal = showLoginModal;
  },
  clearRerouteProps(state) {
    state.showLoginModal = false;
    state.routeBack = '';
  },
  setBeforeRouteName(state, beforeRouteName) {
    state.beforeRouteName = beforeRouteName;
  },
  setUserMenu(state, menu) {
    state.userMenu = menu;
  },
  setDefaultActiveItem(state, item) {
    state.userDefaultActiveItem = item;
  },
  setLogoutClick(state, logoutClick) {
    state.logoutClick = logoutClick;
  },
  setShowAuthLoginModal(state, showAuthLoginModal) {
    setShowAuthLoginModal(showAuthLoginModal.toString());
    state.showAuthLoginModal = showAuthLoginModal;
  },
  setShowLogoutOverlay(state, showLogoutOverlay) {
    state.showLogoutOverlay = showLogoutOverlay;
  },
  setDoctorRequestsCount(state, doctorRequestsCount) {
    state.doctorRequestsCount = doctorRequestsCount;
  },
  setAppBarMenu(state, appBarMenu) {
    state.appBarMenu = appBarMenu;
  },
  setDoctorsStatistic(state, doctorsStatistic) {
    state.doctorsStatistics = doctorsStatistic;
  },
  setDaRequestsCount(state, daRequestsCount) {
    state.daRequestsCount = daRequestsCount;
  },
  setUpdateProfileCount(state, updateProfileCount) {
    state.updateProfileCount = updateProfileCount;
  },
  setBackendMaintenance(state, backendMaintenance) {
    state.backendMaintenance = backendMaintenance;
  },
  setRegistrationServerErrors(state, errors) {
    state.registrationServerErrors = errors;
  },
  setRegistrarStatusFetched(state) {
    state.registrarStatusFetched = true;
  },
  setShowCookiesModal(state, showCookieModal) {
    state.showCookieModal = showCookieModal;
  },
  setAdvertisementCookies(state, enabled) {
    state.advertisementCookies = enabled;
  }
};

export const getters = {
  getActiveMenuItem: state => state.activeMenuItem,
  getBreadcrumbs: state => state.breadcrumbs,
  getRequestsCount: state => state.requestsCount,
  getNotificationsCount: state => state.notificationsCount,
  getTotalNotificationsCount: state => state.totalNotificationsCount,
  getServerErrors: state => state.serverErrors,
  getNotificationDurationTime: state => state.notificationDurationTime,
  getShowLoginModal: state => state.showLoginModal,
  getBeforeRouteName: state => state.beforeRouteName,
  getAppBarHeight: state => state.appBarHeight,
  getUserMenu: state => state.userMenu,
  getUserDefaultActiveItem: state => state.userDefaultActiveItem,
  getLogoutClick: state => state.logoutClick,
  getShowAuthLogin: state => state.showAuthLoginModal,
  getShowLogoutOverlay: state => state.showLogoutOverlay,
  getDoctorRequestsCount: state => state.doctorRequestsCount,
  getAppBarMenu: state => state.appBarMenu,
  getDoctorsStatistic: state => state.doctorsStatistics,
  getDaRequestsCount: state => state.daRequestsCount,
  getUpdateProfileCount: state => state.updateProfileCount,
  getBackendMaintenance: state => state.backendMaintenance,
  getRegistrationServerErrors: state => state.registrationServerErrors,
  getRegistrarStatusFetchedInfo: state => state.registrarStatusFetched,
  getShowCookiesModal: state => state.showCookieModal,
  getAdvertisementCookies: state => state.advertisementCookies
};
