export default function({ $axios, store }) {
    $axios.onRequest((config) => {
        config.headers.common['Accept-Language'] = 'cs-CZ';
    });

    $axios.onResponse((response) => {
        if (response.status >= 200 && response.status < 300 && store.getters.getBackendMaintenance) {
            store.commit('setBackendMaintenance', false);
        }
    });

    $axios.onError((error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 502 && !store.getters.getBackendMaintenance) {
            store.commit('setBackendMaintenance', true);
        }
    });
};
