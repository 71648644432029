<template>
    <div
        id="prihlasit-se"
        class="layer login"
        :class="{'opened': show}"
    >
        <div class="over">
            <div class="closelayer" @click="closeLoginModal"/>
            <div class="block">
                <div class="closelayer" @click="closeLoginModal"/>
                <div class="overinner">
                    <a class="ic_close_layer" @click="closeLoginModal">{{ $t('c_login.c_close') }}</a>
                    <v-carousel
                        v-model="itemNumber"
                        hide-delimiters
                        :show-arrows="false"
                    >
                        <v-carousel-item>
                            <div class="inner position-relative">
                                <h2 class="frank-bold" :class="{'mb-0': errors.length > 0}">{{ $t('c_login.c_title') }}</h2>
                                <collapse-transition>
                                    <div v-if="errors.length > 0" class="error-messages-wrapper">
                                        <div v-for="(error, index) in errors" :key="index">
                                            {{ error.message }}
                                        </div>
                                    </div>
                                </collapse-transition>
                                <div v-if="showResetPasswordMessage" class="reset-message-wrapper">{{ $t('c_login.c_resetPasswordSent') }}</div>
                                <form @submit.prevent="onSubmit">
                                    <div class="overinput">
                                        <label>{{ $t('c_login.c_yourEmail') }}:</label>
                                        <input
                                            ref="email"
                                            v-model="login.credentials.email"
                                            type="email"
                                            :class="{'is-invalid': isEmailInvalid}"
                                            @blur="dirtyEmail = true"
                                        >
                                        <div class="invalid-feedback" :class="{'d-block': isEmailInvalid}">
                                            {{ $getFieldError($v.login.credentials.email) }}
                                        </div>
                                    </div>
                                    <div class="overinput">
                                        <label>{{ $t('c_common.c_labels.c_password') }}:</label>
                                        <div class="d-flex">
                                            <input
                                                id="password"
                                                v-model="login.credentials.password"
                                                :type="visibilityIcon ? 'text' : 'password'"
                                                :class="{'is-invalid': isPasswordInvalid}"
                                                @blur="dirtyPassword = true"
                                            >
                                            <div id="eye">
                                                <i
                                                    :class="[visibilityIcon ? 'fa-eye' : 'fa-eye-slash']"
                                                    class="fas"
                                                    @click="visibilityIcon = !visibilityIcon"
                                                />
                                            </div>
                                        </div>
                                        <div class="invalid-feedback" :class="{'d-block': isPasswordInvalid}">
                                            {{ $getFieldError($v.login.credentials.password) }}
                                        </div>
                                    </div>
                                    <div class="section-wrapper">
                                        <label
                                            class="check"
                                            :class="{'active': login.stay_signed_in}"
                                            for="stay-signed-in"
                                            @click="login.stay_signed_in = !login.stay_signed_in"
                                        > {{ $t('c_login.c_stayLogIn') }} </label>
                                        <input id="stay-signed-in" type="checkbox">
                                        <a
                                            v-if="!$_isMobile"
                                            class="fright forget-pass"
                                            @click="itemNumber++"
                                        >{{ $t('c_common.c_titles.c_forgotPassword') }}</a>
                                    </div>
                                    <v-btn
                                        light
                                        :ripple="false"
                                        :loading="loading"
                                        class="log-in"
                                        type="submit"
                                    >
                                        {{ $t('c_login.c_title') }}
                                    </v-btn>
                                    <div v-if="$_isMobile" class="mobile-forget-pass">
                                        <a
                                            @click="itemNumber++"
                                        >{{ $t('c_login.c_forgetPasswordQeustion') }}</a>
                                    </div>
                                </form>
                            </div>
                        </v-carousel-item>
                        <v-carousel-item>
                            <app-forget-password
                                :credentials="login.credentials"
                                @back="itemNumber--"
                                @submit="onForgetPasswordSubmit"
                            />
                        </v-carousel-item>
                    </v-carousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { CollapseTransition } from 'vue2-transitions';
    import AppForgetPassword from './AppForgetPassword';
    import { removeHtmlClass } from '@/utils/htmlUtils';
    import { setAuthInfo, setUserStatus, removeLogout } from '@/utils/localStorageUtils';
    import { deepCopy } from '@/utils/arrayUtils';
    import tokenMixin from '@/mixins/tokenMixin';
    import { vuelidateMessagesMixin, required, email } from '@/mixins/vuelidateMessagesMixin';
    import menuMixin from '@/mixins/menuMixin';
    import cssBreakpointsMixin from '@/mixins/cssBreakpointsMixin';

    const defaultLogin = Object.freeze({
        credentials: {
            email: '',
            password: ''
        },
        stay_signed_in: false
    });

    export default {
        name: 'AppLogin',
        components: { AppForgetPassword, CollapseTransition },
        mixins: [tokenMixin, menuMixin, vuelidateMessagesMixin, cssBreakpointsMixin],
        props: {
            show: {
                type: Boolean,
                required: true
            }
        },
        validations() {
            return {
                login: {
                    credentials: {
                        email: {
                            required: required(),
                            email: email()
                        },
                        password: {
                            required: required()
                        }
                    }
                }
            };
        },
        data() {
            return {
                dirtyEmail: false,
                dirtyPassword: false,
                errors: [],
                itemNumber: 0,
                showResetPasswordMessage: false,
                loading: false,
                visibilityIcon: false,
                login: deepCopy(defaultLogin)
            };
        },
        computed: {
            isEmailInvalid() {
                return this.$v.login.credentials.email.$invalid && (this.dirtyEmail || this.$v.login.credentials.email.$error);
            },
            isPasswordInvalid() {
                return this.$v.login.credentials.password.$invalid && (this.dirtyPassword || this.$v.login.credentials.password.$error);
            }
        },
        watch: {
            show() {
                if (this.show) {
                    this.$nextTick(() => this.$refs.email.focus());
                }
            }
        },
        methods: {
            closeLoginModal() {
                removeHtmlClass('noscroll');
                document.body.classList.remove('noscroll');
                this.itemNumber = 0;
                this.showResetPasswordMessage = false;
                this.$store.commit('clearRerouteProps');
                this.$emit('update:show', false);
                this.login = deepCopy(defaultLogin);
            },
            async onSubmit() {
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    try {
                        this.loading = true;
                        const authInfo = await this.$axios.$post('/auth', this.login);
                        removeLogout();
                        setAuthInfo(authInfo);
                        this.$axios.setToken(authInfo.auth.access_token, 'Bearer');
                        // In case registrar check status
                        if (authInfo.role === 'registrar') {
                            const fetchedRegistrar = await this.$axios.$get(`/registrars/${authInfo.user.id}`, {
                                params: { includeRequest: true }
                            });
                            setUserStatus(fetchedRegistrar.request.status);
                        }
                        this.closeLoginModal();
                        if (Object.keys(this.$route.query).includes('loginRedirectUrl')) {
                            await this.$router.push(this.$route.query.loginRedirectUrl.toString());
                        } else {
                            await this.$router.push({ name: this.$getDefaultRoleRoute(authInfo.role) });
                        }
                    } catch (error) {
                        this.errors = [{ message: this.$t('c_notifications.c_errorBackendConnect') }];
                        if (error.response) {
                            if (error.response.data) {
                                this.errors = error.response.data.errors;
                            }
                        }
                    } finally {
                        this.loading = false;
                    }
                }
            },
            onForgetPasswordSubmit() {
                this.showResetPasswordMessage = true;
                this.itemNumber--;
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/variables";

    .v-application .v-application--wrap {
        .reset-message-wrapper {
            margin-top: -3rem;
            margin-bottom: 4rem;
            color: color('succces-primary');
            background: color('succces-secondary');
            padding: 1rem;
            border: 1px solid color('succces-primary');
        }

        .mobile-forget-pass {
            padding-top: 1.5rem;

            a {
                color: color('primary');
                font-size: 1rem;
            }
        }

        .error-messages-wrapper {
             margin: 1rem 0 4rem 0;
        }

        .check {
            +::v-deep input {
                display: none;
                visibility: hidden;
            }
        }

        .d-block {
            display: block !important;
        }

        .invalid-feedback {
            position: absolute;
            display: none;
            color: color('error-form');
            font-size: 0.875rem;
            bottom: 1.5rem;
            z-index: 10;
        }

        .layer.login {
            ::v-deep .v-carousel, ::v-deep .v-window__container, .v-carousel .v-window-item, ::v-deep.v-responsive, ::v-deep.v-responsive__content {
                height: auto!important;
                min-height: 40rem!important;
            }

            #password {
                width: 90%;
                border-right: 0 !important;
            }

            #password.is-invalid {
                + #eye {
                    border-width: 1px 1px 1px 0;
                    border-color: color("error-form");
                    border-style: solid;
                }
            }

            #eye {
                background-color: white;
                color: black;
                margin-bottom: 55px;
                width: 4rem;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;

                i {
                    cursor: pointer;
                }
            }

            .log-in {
                height: 3rem;
                background: transparent;
                box-shadow: none;
                transition: background-color 0.6s, color 0.6s;

                &:hover {
                    background: #1baae3;
                }
            }
        }
    }

    .section-wrapper {
        display: flex;
        justify-content: space-between;
        .fright.forget-pass {
            margin-top: -29px;
            float: right;
        }
    }

    .v-application .v-application--wrap .landing-page-wrapper .layer button {
        display: flex;
        margin: auto;
        margin-top: 1rem;
    }
</style>
