<template>
    <error-page
        :title="errorObj.title"
        :message="errorObj.message"
        :image-path="errorObj.imagePath"
        :back-button-text="$t('c_common.c_buttons.c_back')"
        :hide-back-button="!userAuth"
        @button-click="onBackButtonClick"
    />
</template>

<script>
    import ErrorPage from '@/components/ErrorPage';
    import { getUserRole, isUserAuth, clearLocalStorage } from '@/utils/localStorageUtils';
    import menuMixin from '@/mixins/menuMixin';

    export default {
        name: 'Error',
        layout: () => isUserAuth() ? 'authorized' : 'unauthorized',
        components: { ErrorPage },
        mixins: [menuMixin],
        props: {
            error: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                title: this.message,
                meta: [
                    {
                        name: 'viewport',
                        content: 'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no'
                    }
                ],
                error404: {
                    title: 'Stránka nebyla nalezena',
                    message: 'Bohužel stránka, kterou chcete navštívit, byla smazána nebo neexituje.',
                    imagePath: '/img/lost.svg'
                },
                error403: {
                    title: 'Přístup odepřen',
                    message: 'Omlouváme se, ale k této stránce nemáte požadovaná přístupová práva.',
                    imagePath: '/img/security.svg'
                },
                errorDefault: {
                    title: 'Dočasný vypadek na serveru',
                    message: null,
                    imagePath: '/img/maintanance.svg'
                },
                userAuth: false,
                userRole: ''
            };
        },
        computed: {
            statusCode() {
                return (this.error && this.error.statusCode) || 500;
            },
            errorObj() {
                switch (this.statusCode) {
                    case 403: return this.error403;
                    case 404: return this.error404;
                    default: return this.errorDefault;
                }
            },
            defaultActiveItem() {
                return this.$store.getters.getUserDefaultActiveItem;
            }
        },
        mounted() {
            this.userAuth = isUserAuth();
            this.userRole = getUserRole();
            // Wait for store initialization
            setTimeout(() => {
                // In case expiring refresh token clear local storage and reload page
                if (this.isRefreshTokenExpired && this.userAuth) {
                    clearLocalStorage();
                    this.$router.go();
                }
            }, 200);
        },
        methods: {
            onBackButtonClick() {
                this.$router.push({ name: this.$getDefaultRoleRoute(this.userRole) });
            }
        }
    };
</script>
