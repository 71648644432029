export default {
    methods: {
        $_connectionError() {
            this.$notify({
                group: 'app',
                type: 'error',
                duration: 5000,
                title: this.$t('c_notifications.c_error'),
                text: this.$t('c_notifications.c_serverCommunication')
            });
        },
        $_errorNotification(message) {
            this.$notify({
                group: 'app',
                type: 'error',
                duration: 5000,
                title: this.$t('c_notifications.c_error'),
                text: message
            });
        },
        $_notification(
            message,
            type = 'success',
            title = '',
            duration = this.$store.getters.getNotificationDurationTime,
            group = 'app'
        ) {
            this.$notify({
                group: group,
                type: type,
                duration: duration,
                title: title,
                text: message
            });
        },
        $_clearNotifications(group = 'app') {
            this.$notify({ group: group, clean: true });
        }
    }
};
