<template>
    <div id="app-drawer">
        <v-navigation-drawer
            app
            clipped
            v-bind="$attrs"
            :value="isMobile ? value : true"
            :temporary="isMobile"
            :mini-variant="isMobile ? false : expand"
            width="270"
            mini-variant-width="80"
            floating
            @input="$emit('input', $event)"
        >
            <template v-if="$_isMobile" v-slot:prepend>
                <v-img
                    src="/img/clk_logo.png"
                    width="200"
                    class="mx-auto my-8"
                />
            </template>
            <v-list nav>
                <v-list-item
                    v-for="item in menu"
                    :key="item.id"
                    :class="{'active': item.id === activeMenuItem}"
                    @click="onItemClick(item)"
                >
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <v-list-item-icon v-if="item.icon" v-on="on">
                                <i :class="[ item.icon ]"/>
                            </v-list-item-icon>
                            <v-list-item-action>{{ item.badge ? item.badge : '' }}</v-list-item-action>
                            <v-list-item-content>{{ item.label }}</v-list-item-content>
                        </template>
                        <span>{{ item.label }}</span>
                    </v-tooltip>
                </v-list-item>
            </v-list>
            <template v-if="!expand && roleDoctor" v-slot:append>
                <div
                    v-for="(banner, index) in selectedBanners"
                    :key="index"
                    class="drawer-banner-wrapper"
                >
                    <v-tooltip right :disabled="!banner.tooltip">
                        <template v-slot:activator="{ on }">
                            <v-img
                                :src="banner.url"
                                height="120"
                                width="240"
                                class="drawer-banner"
                                :class="{ 'cursor-pointer' : banner.link }"
                                v-on="on"
                                @click.prevent="windowOpen(banner.link, '_blank')"
                            />
                        </template>
                        <div> {{ banner.tooltip }}</div>
                    </v-tooltip>
                    <div v-if="banner.text">
                        <a v-if="banner.text_link" @click.prevent="windowOpen(banner.text_link, '_blank')">{{ banner.text }}</a>
                        <span v-else class="banner-text">{{ banner.text }}</span>
                    </div>
                </div>
            </template>
        </v-navigation-drawer>
    </div>
</template>

<script>
    import tokenMixin from '@/mixins/tokenMixin';
    import cssBreakpointsMixin from '@/mixins/cssBreakpointsMixin';
    import { getUserRole, getDoctorsSpecialities } from '@/utils/localStorageUtils';
    import { windowOpen } from '@/utils/htmlUtils';
    import 'url-search-params-polyfill';

    export default {
        name: 'AppNavigation',
        mixins: [tokenMixin, cssBreakpointsMixin],
        props: {
            value: {
                type: Boolean,
                required: true
            },
            isMobile: {
                type: Boolean,
                required: true
            },
            expand: {
                type: Boolean
            }
        },
        data() {
            return {
                userRole: '',
                doctorsSpecialities: [],
                advertisementGroups: [],
                selectedBanners: [],
                parsedAdvertisementGroups: [],
                roles: ['clk', 'registrator', 'admin', 'okresni-sdruzeni', 'lekar'],
                menuItemExceptions: [],
                windowOpen
            };
        },
        computed: {
            activeMenuItem() {
                return this.$store.getters.getActiveMenuItem;
            },
            menu() {
                return this.$store.getters.getUserMenu;
            },
            roleCmc() {
                return this.userRole === 'cmc';
            },
            roleDoctor() {
                return this.userRole === 'doctor';
            },
            roleRegistrar() {
                return this.userRole === 'registrar';
            }
        },
        watch: {
            menu() {
                this.parseLink();
            },
            $route() {
                this.parseLink();
                if (this.roleDoctor) {
                    this.resetBanners();
                }
            }
        },
        async mounted() {
            this.userRole = getUserRole();

            if (this.roleDoctor) {
                this.doctorsSpecialities = getDoctorsSpecialities();
                await this.fetchAds();
            }

            const commonMenuItemExceptions = [{ name: 'nova', menuItem: 'nova' }];
            switch (this.userRole) {
                case 'registrar': this.menuItemExceptions = commonMenuItemExceptions; break;
                case 'super_admin':
                case 'cmc':
                case 'district_association': {
                    this.menuItemExceptions = [
                        ...commonMenuItemExceptions,
                        { name: 'kredity', menuItem: 'zadosti-lekaru' },
                        { name: 'diplomy', menuItem: 'zadosti-lekaru' }
                    ];
                    break;
                }
            }
        },
        methods: {
            async fetchAds() {
                try {
                    const params = new URLSearchParams();
                    this.doctorsSpecialities.forEach((speciality) => {
                        params.append('specialitiesIds', speciality.id);
                    });
                    ({ advertisement_groups: this.advertisementGroups } =
                        await this.$axios.$get('/premium/surfaces/active', { params: params }));
                    this.parseAdvertisementGroups();
                } catch (error) {
                    this.$_handleConnectionError(error);
                }
            },
            // Generate random number not include endRange
            generateCoefficient(endRange) {
                return Math.round(Math.random() * (endRange - 1));
            },
            parseAdvertisementGroups() {
                this.advertisementGroups.forEach((advertisementGroup) => {
                    const initialValue = 0;
                    this.parsedAdvertisementGroups.push({
                        // Sum of all coverages in group
                        coverageSum: advertisementGroup.advertisements.reduce(
                            (accumulator, currentValue) => accumulator + currentValue.banner.coverage
                            , initialValue
                        ),
                        banners: advertisementGroup.advertisements.map((advertisement) => {
                            return {
                                coverageRange: {},
                                ...advertisement.banner
                            };
                        }),
                        // Decision which banners will be shown is based on generated coefficient
                        generatedCoefficient: 1
                    });
                });

                this.parsedAdvertisementGroups.forEach((advertisementGroup) => {
                    // Iterator for calculating advertisement coverage range
                    let currentCoverage = 0;
                    // Generate random number in range from 0 to coverageSum
                    advertisementGroup.generatedCoefficient = this.generateCoefficient(advertisementGroup.coverageSum);
                    advertisementGroup.banners.forEach((banner) => {
                        banner.coverageRange.start = currentCoverage;
                        banner.coverageRange.end = currentCoverage + banner.coverage - 1;
                        // In case generatedCoefficient is in current banner range add this banner to array of selected banners
                        if (advertisementGroup.generatedCoefficient >= banner.coverageRange.start &&
                            advertisementGroup.generatedCoefficient <= banner.coverageRange.end) {
                            this.selectedBanners.push(banner);
                        }
                        currentCoverage += banner.coverage;
                    });
                });
            },
            resetBanners() {
                this.selectedBanners = [];
                this.parsedAdvertisementGroups.forEach((advertisementGroup) => {
                    // Generate random number in range from 0 to coverageSum
                    advertisementGroup.generatedCoefficient = this.generateCoefficient(advertisementGroup.coverageSum);
                    advertisementGroup.banners.forEach((banner) => {
                        // In case generatedCoefficient is in current banner range add this banner to array of selected banners
                        if (advertisementGroup.generatedCoefficient >= banner.coverageRange.start &&
                            advertisementGroup.generatedCoefficient <= banner.coverageRange.end) {
                            this.selectedBanners.push(banner);
                        }
                    });
                });
            },
            onItemClick(menuItem) {
                if (menuItem.externalLink) {
                    window.open(menuItem.externalLink, '_blank');
                    return;
                }
                this.$store.commit('setMenuItem', menuItem.id);
                this.$router.push({ name: menuItem.route });
            },
            parseLink() {
                if (this.$route.fullPath) {
                    let routeFullPath = this.$route.fullPath;
                    // Check for query params in path
                    const questionMarkIndex = routeFullPath.indexOf('?');
                    // In case question mark is in path, remove query params from path
                    if (questionMarkIndex !== -1) {
                        routeFullPath = routeFullPath.slice(0, questionMarkIndex);
                    }
                    const secondSlashIndex = routeFullPath.indexOf('/', 1);
                    if (secondSlashIndex !== -1) {
                        const firstSlashIndex = routeFullPath.indexOf('/');
                        const mainRoute = routeFullPath.substring(firstSlashIndex + 1, secondSlashIndex);

                        const thirdSlashIndex = routeFullPath.indexOf('/', (secondSlashIndex + 1));
                        const subRoute = routeFullPath.substring(secondSlashIndex + 1, thirdSlashIndex !== -1 ? thirdSlashIndex : routeFullPath.length);
                        const exceptionIndex = this.menuItemExceptions.findIndex(exception => exception.name === subRoute);

                        if (exceptionIndex !== -1) {
                            this.$store.commit('setMenuItem', this.menuItemExceptions[exceptionIndex].menuItem);
                        } else if (this.roles.includes(mainRoute)) {
                            this.$store.commit('setMenuItem', subRoute);
                        } else {
                            this.$store.commit('setMenuItem', mainRoute);
                        }
                    } else {
                        this.$store.commit('setMenuItem', routeFullPath.substring(1));
                    }
                } else {
                    this.$store.commit('setMenuItem', '');
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import '../assets/scss/variables';

    #app-drawer {
        .app-logo {
            width: 90%;
            padding-left: 0.5rem;
            margin-top: 1rem;
        }

        .close-icon {
            padding: 0.5rem;
            margin-left: auto;
        }

        .v-list-item.active {
            border-right: 0.2rem solid color('primary');
            background-color: color('secondary');
        }

        .v-list-item__action {
            margin-right: 1rem;
        }

        .v-navigation-drawer.v-navigation-drawer--mini-variant {
            .v-list-item.active {
                .v-list-item__icon {
                    margin-left: 0.2rem;
                }
            }
        }

        .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
            color: gray!important;
        }

        .v-list-item.v-list-item--link.theme--light.active {
            color: color('primary')!important;
        }

        .nav-divider {
            margin: 1rem auto;
            width: 85%;
        }

        .v-list {
            padding: 1rem 0;
        }

        .v-list--nav .v-list-item {
            border-radius: 0;
            padding: 0 2rem;
            margin-bottom: 0;
        }

        .theme--light.v-navigation-drawer {
            box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 1px
        }

        .drawer-banner-wrapper {
            max-width: 240px;
            margin: 0 auto 1rem;

            .drawer-banner {
                margin-bottom: 0.2rem;
            }

            .banner-text {
                color: gray;
                font-size: 0.9rem;
            }

            a {
                @extend .banner-text;
                text-decoration: underline;
            }

            &:last-of-type {
                margin-bottom: 3rem;
            }
        }

        .cursor-pointer {
            cursor: pointer;
        }

        @media only screen and (max-width: breakpoint('md')) {
            .app-logo {
                padding-left: 0;
            }

            .v-list {
                border-top: 0.5px solid gainsboro;;
                border-radius: 0;
            }
        }
    }

    // Should be refactored
    .v-list-item {
        padding: 0 2rem;

        .v-list-item__content {
            white-space: nowrap;
        }

        .v-list-item__action {
            position: absolute;
            top: 0;
            right: 0;
            padding-top: 2px;
        }
    }

    .v-list-item__icon {
        font-size: 1.2rem
    }
</style>
