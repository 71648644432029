<template>
    <div class="cookies-modal">
        <div v-if="!showWarning" class="cookies-modal-card">
            <div class="cookies-modal-card__header">
                <img
                    src="https://general-storage.b-cdn.net/cookie_logo.svg"
                    alt="cookie icon"
                    width="70"
                >
                <div>
                    Nastavení cookies
                </div>
            </div>

            <div class="cookies-modal-card__body">
                <div class="cookies-setting">
                    <div>
                        <div class="cookies-setting__title">
                            Technické cookies
                        </div>
                        <div class="cookies-setting__text">
                            Technické cookies jsou nezbytné pro správné fungování webu a všech funkcí, které nabízí. Tyto cookies pomáhají, aby byla webová stránka použitelná tak, že umožní základní funkce jako navigace stránky a přístup k zabezpečeným sekcím webové stránky. Webová stránka nemůže správně fungovat bez těchto cookies.
                        </div>
                    </div>
                    <div class="necessary-cookie-icon">
                        <img
                            src="/img/correct_sign.svg"
                            width="40"
                        >
                    </div>
                </div>
                <div class="cookies-setting">
                    <div>
                        <div class="cookies-setting__title">
                            Analytické cookies
                        </div>
                        <div class="cookies-setting__text">
                            Analytické cookies pomáhají majitelům webových stránek, aby porozuměli, jak návštěvníci používají webové stránky. Anonymně sbírají a sdělují informace.
                        </div>
                    </div>
                    <label class="switch">
                        <input v-model="analyticsCookies" type="checkbox">
                        <span class="slider"/>
                    </label>
                </div>
                <div class="cookies-setting">
                    <div>
                        <div class="cookies-setting__title">
                            Reklamní cookies
                        </div>
                        <div class="cookies-setting__text">
                            Reklamní cookies používají naši partneři, aby Vám mohli zobrazit vhodné obsahy nebo reklamy na stránkách třetích subjektů.
                        </div>
                    </div>
                    <label class="switch">
                        <input v-model="advertisementCookies" type="checkbox">
                        <span class="slider"/>
                    </label>
                </div>
            </div>
            Tento web používá cookies. Díky nim vidíte jen to, co skutečně chcete. <a href="https://soubory-vzdelavani-l.b-cdn.net/Cookies.pdf" target="_blank">Více informací</a>
            <div class="cookies-modal-card__actions">
                <button class="secondary-btn" @click="onSaveSettings">Uložit nastavení</button>
                <button class="primary-btn" @click="onAcceptAll">Přijmout vše</button>
            </div>
        </div>
        <div v-else class="cookies-modal-warning">
            <div class="cookies-modal-warning__header">
                <div>Vypnutí odesílání analytických dat</div>
            </div>
            <p>
                Pro deaktivaci odesílání analytických dat je potřebné stránku znovu načíst.
            </p>
            <p>
                Prosím, klikněte na tlačítko "Načíst znovu" pro obnovení stránky. Pokud se rozhodnete možnost "Odložit", odesílání analytických dat bude pokračovat až do doby, kdy stránku znovu načtete.
            </p>
            <div class="cookies-modal-warning__actions">
                <button class="secondary-btn" @click="cancelRefresh">Odložit</button>
                <button class="primary-btn" @click="refresh">Načíst znovu</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CookiesModal',
        props: {
            savedAnalyticsCookies: {
                type: Boolean,
                default: false
            },
            savedAdvertisementCookies: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                analyticsCookies: false,
                advertisementCookies: false,
                showWarning: false
            };
        },
        mounted() {
            this.analyticsCookies = this.savedAnalyticsCookies;
            this.advertisementCookies = this.savedAdvertisementCookies;
        },
        methods: {
            onSaveSettings() {
                this.$emit('onSaveSettings', {
                    analyticsCookies: this.analyticsCookies,
                    advertisementCookies: this.advertisementCookies
                });

                if (this.analyticsCookies) {
                    this.$gtm.enable(true);
                    this.$store.commit('setShowCookiesModal', false);
                } else {
                    this.$gtm.enable(false);
                    this.showWarning = true;
                }
            },
            onAcceptAll() {
                this.$emit('onSaveSettings', {
                    analyticsCookies: true,
                    advertisementCookies: true
                });

                this.$gtm.enable(true);

                this.$store.commit('setShowCookiesModal', false);
            },
            refresh() {
                this.$store.commit('setShowCookiesModal', false);
                this.showWarning = false;
                window.location.reload();
            },
            cancelRefresh() {
                this.$store.commit('setShowCookiesModal', false);
                this.showWarning = false;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .cookies-modal {
        width: 100%;
        height: 100vh;
        background: rgba(128, 128, 128, 0.582);
        position: fixed;
        top: 0;
        z-index: 100;
        display: flex;
        justify-items: center;
        align-items: center;
    }

    .necessary-cookie-icon {
        margin-left: 2rem;
    }

    .cookies-modal-card {
        max-width: 50rem;
        max-height: 100%;
        overflow-y: auto;
        background: white;
        padding: 2rem;
        margin: auto;
        &__header {
            display: flex;
            font-size: 2rem;
            color: black;
            align-items: center;
            margin-bottom: 2rem;

            img {
                margin-right: 1rem;
            }
        }

        &__actions {
            margin-top: 2rem;
            text-align: right;

            button {
                padding: 0.5rem 1rem;
                width: 10rem ;
            }

            .secondary-btn {
                background: whitesmoke;
                border: 1px solid gray;
                margin-right: 1rem;
            }

            .primary-btn {
                background: #40bced;
                color: white;
                border: 1px solid #40bced;
            }
        }
    }

    .cookies-setting {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid gainsboro;
        &__title {
            font-size: 1.2rem;
            margin-bottom: 1rem;
        }
    }

    //switch styles
    .switch {
        position: relative;
        display: inline-block;
        min-width: 60px;
        margin-left: 2rem;
        height: 34px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 34px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked + .slider {
        background-color: #40bced;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #40bced;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
    .cookies-modal-warning {
        max-width: 50rem;
        max-height: 100%;
        overflow-y: auto;
        background: white;
        padding: 2rem;
        margin: auto;

        &__header {
            display: flex;
            font-size: 2rem;
            color: black;
            align-items: center;
            margin-bottom: 1rem;
        }

        p {
            margin-bottom: .5rem;
        }

        &__actions {
            margin-top: 2rem;
            text-align: right;

            button {
                padding: 0.5rem 1rem;
                width: 10rem ;
            }

            .secondary-btn {
                background: whitesmoke;
                border: 1px solid gray;
                margin-right: 1rem;
            }

            .primary-btn {
                background: #40bced;
                color: white;
                border: 1px solid #40bced;
            }
        }

        &__countdown {
            margin-top: 1rem;
            font-size: 1.2rem;
            color: rgb(184, 184, 184);
            font-weight: 600;
            text-align: center;
        }
    }

</style>