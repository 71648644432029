import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const excludeRouteNames = [
    'aktivity-owner',
    'aktivity-public',
    'portaly-cmc',
    'portaly-registrar'
];

export function createRouter(ssrContext, createDefaultRouter) {
    const options = createDefaultRouter(ssrContext).options;

    return new Router({
        ...options,
        routes: fixRoutes(options.routes)
    });
}

function fixRoutes(defaultRoutes) {
    // default routes that come from `pages/`
    return defaultRoutes.filter(route => !excludeRouteNames.includes(route.name));
}