<template>
    <v-app id="unauthorized">
        <!-- Foxentry start -->
        <script type="text/javascript">
            var Foxentry;
        </script>
        <!-- Foxentry end -->

        <!-- global instant notifications -->
        <notification-messages/>
        <!-- global instant notifications maintenance group -->
        <notification-messages group="maintenance"/>
        <div class="content">
            <div>
                <div class="landing-page-wrapper">
                    <div class="page">
                        <landing-header @login-click="onLoginClick" @registration-click="onRegistrationClick"/>
                    </div>
                    <app-login :show.sync="showLogin"/>
                    <app-registration :show.sync="showRegistration"/>
                </div>
                <div class="back-to-home">
                    <a @click="onBackClick">
                        <v-icon color="primary" left>
                            mdi-arrow-left
                        </v-icon>
                        <span>{{ $t("c_common.c_buttons.c_backToHome") }}</span>
                    </a>
                </div>
                <div>
                    <message-box
                        :messages="serverErrorsMessages"
                        class="error-message-box"
                        type="error-message"
                    />
                </div>
                <nuxt/>
            </div>
        </div>
        <app-footer/>

        <!-- Cookies confirm-->
        <cookies-settings/>
    </v-app>
</template>

<script>
    import { mapGetters } from 'vuex';
    import AppFooter from '@/components/AppFooter';
    import AppLogin from '@/components/LandingPage/AppLogin';
    import AppRegistration from '@/components/LandingPage/AppRegistration';
    import CookiesSettings from '../components/CookiesSettings.vue';
    import MessageBox from '@/components/MessageBox';
    import NotificationMessages from '@/components/NotificationMessages';
    import notificationsMixin from '@/mixins/notificationsMixin';
    import LandingHeader from '@/components/LandingPage/LandingHeader';
    import { addHtmlClass } from '@/utils/htmlUtils';

    export default {
        components: { AppFooter, AppLogin, AppRegistration, MessageBox, NotificationMessages, LandingHeader, CookiesSettings },
        mixins: [notificationsMixin],
        data() {
            return {
                showLogin: false,
                showRegistration: false
            };
        },
        computed: {
            ...mapGetters(['getBackendMaintenance']),
            serverErrorsMessages() {
                const serverErrors = this.$store.getters.getServerErrors;
                return serverErrors.map(error => error.message);
            }
        },
        watch: {
            getBackendMaintenance() {
                if (this.getBackendMaintenance) {
                    this.$_notification(this.$t('c_notifications.c_maintenance'), 'maintenance', this.$t('c_notifications.c_maintenanceTitle'), -1, 'maintenance');
                } else {
                    this.$_clearNotifications('maintenance');
                }
            }
        },
        mounted() {
            this.$store.commit('clearServerErrors');

            /* eslint-disable */
            const e = document.querySelector("script"), s = document.createElement('script');
            s.setAttribute('type', 'text/javascript');
            s.setAttribute('async', 'true');
            s.setAttribute('src', 'https://cdn.foxentry.cz/lib');
            e.parentNode.appendChild(s);
            s.onload = function(){ Foxentry = new FoxentryBase(process.env.localDev ? 'D1oPUKtMgV' : 'LFr8h1wK4L'); }
            /* eslint-enable */
        },
        methods: {
            onBackClick() {
                this.$store.commit('clearServerErrors');
                this.$router.push({ name: 'index', query: { ...this.$route.query } });
            },
            onLoginClick() {
                addHtmlClass('noscroll');
                document.body.classList.add('noscroll');
                this.showLogin = true;
            },
            onRegistrationClick() {
                addHtmlClass('noscroll');
                document.body.classList.add('noscroll');
                this.showRegistration = true;
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import '../assets/scss/variables';

    #unauthorized {
        background: url("/img/registration_bcg.png");
        background-size: cover;

        .landing-page-wrapper {
            min-height: auto;

            ::v-deep #landing-page-header {
                background: white;
            }

            ::v-deep .fix {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) ;
            }

            ::v-deep .ic_menu {
                display: none;
            }
        }

        .error-messages-wrapper {
            margin-top: 2rem;
            margin-bottom: -2rem;
        }

        .content {
            display: flex;
            justify-content: center;

            .error-message-box {
                margin: 1rem 0;
            }

        }

        ::v-deep #not-found {
            background: white;
            border-radius: 1rem;
            padding: 3rem;
            box-shadow: $box-shadow-sm;
            margin-bottom: 5rem;
        }

        @media screen and (max-width: 1000px){
             ::v-deep #activity-detail {
                 width: 50rem;
             }
        }
        @media screen and (max-width: 800px ){
             ::v-deep #activity-detail {
                 width: 38rem;
             }
        }

         @media (max-width: 1279px) {
            .content {
                margin-bottom: 40rem;
                padding: 1rem;
            }

            ::v-deep #not-found {
                padding: 1rem;
            }

            ::v-deep .v-image {
                width: 18.125rem;
                margin-top: 1rem;
                margin: auto;
            }

            ::v-deep #activity-detail {
                width: auto;
            }

            ::v-deep .login {
                .v-image  {
                    width: auto;
                }
            }
        }

        @media screen and (max-width: 680px) {
            .back-to-home {
                margin-top: 8rem;
            }
        }

        @media screen and (max-width: 320px) {
            .content {
                margin-bottom: 45rem;
            }
        }
    }

    footer {
        width: 100%;
        background: white;
        a {
            text-decoration: none;
            &:hover {
                text-decoration: none;
            }
        }
        .col {
            padding: 1rem 2rem 1rem 2rem;
        }
    }

    .v-application .v-application--wrap {
        border: 1px solid;
    }

    .back-to-home {
        font-size: 1.25rem;
        margin: 10rem 0rem 1rem 0.5rem;
        a {
            text-decoration: none;
            &:hover {
                text-decoration: none;
            }
            &:hover {
                span {
                    text-decoration: underline
                }
            }
        }
    }

    .footer-title {
        margin-bottom: 1rem
    }

    .footer-icon {
        margin-bottom: 0.5rem;
        a {
            &:hover {
                text-decoration: none;
            }
        }
        span {
            padding-left: 1rem;
        }
        &:hover {
            .mail {
                text-decoration: underline;
            }
        }
    }

    .footer-text {
        text-align: center;
        small {
            background: color('light-gray');
            padding: 5px 10px;
            border-radius: 1.5rem;
        }
    }

    .message-wrapper {
        margin: auto;
        margin-top: 2rem;
    }

    .content {
        margin: 0 auto 20rem auto;
    }
</style>
