import menuMixin from '@/mixins/menuMixin';
import { isUserAuth, getUserRole } from './localStorageUtils';
import { routeWithQueryParams } from '@/utils/stringUtils';

export function checkUserRole(store, redirect, route, error) {
    let routeMetaObj;
    if (route.meta.length > 0) {
        routeMetaObj = route.meta[0];
    } else {
        return;
    }
    const isRouteMetaEmptyObj = Object.keys(routeMetaObj).length === 0 && routeMetaObj.constructor === Object;
    // 1) Check whether defined meta on page
    if (routeMetaObj && !isRouteMetaEmptyObj) {
        // 2) Check whether page have defined access roles list
        if (routeMetaObj.roles) {
            // 3) Get roles from meta page
            const roles = route.meta.map((meta) => {
                if (meta.roles) {
                    return meta.roles;
                }
                return null;
            }).pop();
            // 4) Check whether page is public in case not check user
            const allRolesIndex = roles.findIndex(role => role === 'all');
            if (allRolesIndex === -1) {
                // 5) Check whether user is authenticated
                if (!isUserAuth()) {
                    return redirect({ name: 'index', query: { loginRedirectUrl: routeWithQueryParams(route.path, route.query) } });
                }
                // 6) Check whether user have permission to current page
                const roleIndex = roles.findIndex(role => role === getUserRole());
                if (roleIndex === -1) {
                    return error({ statusCode: 403 });
                }
            }
        }
    } else if (isUserAuth()) {
        // In case user is authenticated, but want to visit non private page, he will be redirected to home page according to his role
        const defaultRoute = menuMixin.methods.defaultRoute(menuMixin.data());
        // Check force redirecting in query params
        const forceRedirect = Object.keys(route.query).includes('loginRedirectUrl') &&
                Object.keys(route.query).includes('redirectAlreadyAuthorized') &&
                route.query.redirectAlreadyAuthorized === 'true';
        // In case force redirect use route from login redirect url query param in other cases redirect to home page
        if (forceRedirect) {
            redirect(route.query.loginRedirectUrl);
        } else {
            redirect({ name: defaultRoute });
        }
    }
}
