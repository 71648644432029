import { getUserRole, getUserStatus } from '@/utils/localStorageUtils';

export default {
    data() {
        return {
            defaultRoutes: {
                cmc: 'zadosti',
                doctor: 'aktivity',
                registrar: 'aktivity',
                super_admin: 'admin-lokalizace',
                district_association: 'zadosti-lekaru'
            },
            $_userRole: '',
            $_userStatus: ''
        };
    },
    computed: {
        defaultActiveItem() {
            return {
                cmc: {
                    id: 'zadosti',
                    label: this.$t('c_common.c_titles.c_requests'),
                    route: 'zadosti'
                },
                doctor: {
                    id: 'aktivity',
                    label: this.$t('c_breadcrumbs.c_doctor.c_activities'),
                    route: 'aktivity',
                    icon: 'fad fa-graduation-cap'
                },
                registrar: this.$_userStatus === 'approved'
                    ? {
                        id: 'aktivity',
                        label: this.$t('c_breadcrumbs.c_registrar.c_activities'),
                        route: 'aktivity',
                        badge: false,
                        icon: 'mdi-image'
                    }
                    : {
                        id: 'schvaleni-registrace',
                        label: this.$t('c_breadcrumbs.c_registrar.c_information'),
                        route: 'registrator-schvaleni-registrace',
                        icon: 'fad fa-user-edit'
                    },
                super_admin: {
                    id: 'lokalizace',
                    label: 'Home',
                    route: 'admin-lokalizace'
                },
                district_association: {
                    id: 'zadosti-lekaru',
                    label: this.$t('c_breadcrumbs.c_district_association.c_doctors_requests'),
                    route: 'zadosti-lekaru',
                    badge: this.daRequestsCount,
                    icon: 'fad fa-file-medical'
                }
            };
        },
        baseRegistrarMenu() {
            return [
                {
                    id: 'aktivity',
                    label: this.$t('c_breadcrumbs.c_registrar.c_activities'),
                    route: 'aktivity',
                    icon: 'fad fa-graduation-cap'
                },
                {
                    id: 'zadosti',
                    label: this.$t('c_breadcrumbs.c_registrar.c_requests'),
                    route: 'zadosti',
                    badge: this.requestsCount,
                    icon: 'fad fa-file-plus'
                },
                {
                    id: 'nova',
                    label: this.$t('c_common.c_buttons.c_create'),
                    route: 'aktivity-nova',
                    icon: 'fas fa-plus'
                },
                {
                    id: 'portaly',
                    label: this.$t('c_breadcrumbs.c_registrar.c_portals'),
                    route: 'portaly',
                    icon: 'fad fa-browser'
                },
                {
                    id: 'placene-sluzby',
                    label: this.$t('c_breadcrumbs.c_registrar.c_paid_services'),
                    route: 'placene-sluzby',
                    icon: 'fas fa-bullhorn'
                },
                {
                    id: 'objednavky',
                    label: this.$t('c_breadcrumbs.c_registrar.c_orders'),
                    route: 'objednavky',
                    icon: 'fas fa-receipt'
                },
                {
                    id: 'casto-kladene-otazky',
                    label: 'Časté dotazy',
                    route: 'registrator-casto-kladene-otazky',
                    icon: 'fad fa-question'
                },
                {
                    id: 'manual',
                    label: this.$t('c_breadcrumbs.c_registrar.c_manual'),
                    route: 'registrator-manual',
                    icon: 'fad fa-book-alt'
                }
            ];
        },
        roles() {
            return {
                cmc: [
                    {
                        id: 'zadosti',
                        label: this.$t('c_common.c_titles.c_requests'),
                        route: 'zadosti',
                        badge: this.requestsCount,
                        icon: 'fad fa-file-signature'
                    },
                    {
                        id: 'nova',
                        label: this.$t('c_common.c_buttons.c_createActivity'),
                        route: 'aktivity-nova',
                        icon: 'fas fa-plus'
                    },
                    {
                        id: 'aktivity',
                        label: this.$t('c_breadcrumbs.c_cmc.c_activities'),
                        route: 'aktivity',
                        icon: 'fad fa-graduation-cap'
                    },
                    {
                        id: 'portaly',
                        label: this.$t('c_breadcrumbs.c_cmc.c_approved_portals'),
                        route: 'portaly',
                        icon: 'fad fa-browser'
                    },
                    {
                        id: 'registratori',
                        label: this.$t('c_breadcrumbs.c_cmc.c_registrars'),
                        route: 'registratori',
                        icon: 'fad fa-user-tie'
                    },
                    {
                        id: 'lekari',
                        label: this.$t('c_breadcrumbs.c_cmc.c_doctors'),
                        route: 'lekari',
                        icon: 'fad fa-user-nurse'
                    },
                    {
                        id: 'zadosti-lekaru',
                        label: this.$t('c_breadcrumbs.c_district_association.c_doctors_requests'),
                        route: 'zadosti-lekaru',
                        icon: 'fad fa-file-medical'
                    },
                    {
                        id: 'objednavky',
                        label: this.$t('c_breadcrumbs.c_registrar.c_orders'),
                        route: 'objednavky',
                        icon: 'fas fa-receipt'
                    },
                    {
                        id: 'placene-sluzby',
                        label: this.$t('c_breadcrumbs.c_registrar.c_paid_services'),
                        route: 'placene-sluzby',
                        icon: 'fas fa-bullhorn'
                    },
                    {
                        id: 'bannery',
                        label: this.$t('c_breadcrumbs.c_super_admin.c_banners'),
                        route: 'bannery',
                        icon: 'far fa-images'
                    },
                    {
                        id: 'videa-prenosy',
                        label: this.$t('c_breadcrumbs.c_doctor.c_videos'),
                        route: 'videa-prenosy',
                        icon: 'fas fa-video'
                    },
                    {
                        id: 'elearning-courses',
                        label: this.$t('c_breadcrumbs.c_doctor.c_courses'),
                        externalLink: 'https://eclk.cz/admin-login',
                        icon: 'fad fa-chalkboard-teacher'
                    }
                ],
                doctor: [
                    {
                        id: 'aktivity',
                        label: this.$t('c_breadcrumbs.c_doctor.c_activities'),
                        route: 'aktivity',
                        icon: 'fad fa-graduation-cap'
                    },
                    {
                        id: 'moje-aktivity',
                        label: this.$t('c_breadcrumbs.c_doctor.c_my_activities'),
                        route: 'lekar-moje-aktivity',
                        icon: 'fad fa-user-graduate'
                    },
                    {
                        id: 'zadosti',
                        label: this.$t('c_breadcrumbs.c_cmc.c_requests'),
                        route: 'zadosti',
                        badge: this.doctorRequestsCount,
                        icon: 'fad fa-file-signature'
                    },
                    {
                        id: 'casto-kladene-otazky',
                        label: 'Časté dotazy',
                        route: 'lekar-casto-kladene-otazky',
                        icon: 'fad fa-question'
                    },
                    {
                        id: 'manual',
                        label: this.$t('c_breadcrumbs.c_doctor.c_manual'),
                        route: 'lekar-manual',
                        icon: 'fad fa-book-alt'
                    },
                    {
                        id: 'videa-prenosy',
                        label: this.$t('c_breadcrumbs.c_doctor.c_videos'),
                        route: 'videa-prenosy',
                        icon: 'fas fa-video'
                    },
                    {
                        id: 'elearning-courses',
                        label: this.$t('c_breadcrumbs.c_doctor.c_courses'),
                        externalLink: 'https://www.eclk.cz/',
                        icon: 'fad fa-chalkboard-teacher'
                    }
                ],
                registrar: this.$_userStatus === 'approved' ? [...this.baseRegistrarMenu] : [
                    {
                        id: 'schvaleni-registrace',
                        label: this.$t('c_breadcrumbs.c_registrar.c_information'),
                        route: 'registrator-schvaleni-registrace',
                        icon: 'fad fa-user-edit'
                    },
                    ...this.baseRegistrarMenu
                ],
                super_admin: [
                    {
                        id: 'lokalizace',
                        label: this.$t('c_breadcrumbs.c_super_admin.c_localization'),
                        route: 'admin-lokalizace',
                        icon: 'far fa-language'
                    },
                    {
                        id: 'diagnostika',
                        label: this.$t('c_breadcrumbs.c_super_admin.c_diagnostics'),
                        route: 'admin-diagnostika',
                        icon: 'fal fa-monitor-heart-rate'
                    },
                    {
                        id: 'emaily',
                        label: this.$t('c_breadcrumbs.c_super_admin.c_emails'),
                        route: 'admin-emaily',
                        icon: 'far fa-at'
                    },
                    {
                        id: 'aktivity',
                        label: this.$t('c_breadcrumbs.c_cmc.c_activities'),
                        route: 'aktivity',
                        icon: 'fad fa-graduation-cap'
                    },
                    {
                        id: 'registratori',
                        label: this.$t('c_breadcrumbs.c_cmc.c_registrars'),
                        route: 'registratori',
                        icon: 'fad fa-user-tie'
                    },
                    {
                        id: 'lekari',
                        label: this.$t('c_breadcrumbs.c_cmc.c_doctors'),
                        route: 'lekari',
                        icon: 'fad fa-user-nurse'
                    },
                    {
                        id: 'bannery',
                        label: this.$t('c_breadcrumbs.c_super_admin.c_banners'),
                        route: 'bannery',
                        icon: 'far fa-images'
                    },
                    {
                        id: 'objednavky',
                        label: this.$t('c_breadcrumbs.c_registrar.c_orders'),
                        route: 'objednavky',
                        icon: 'fas fa-receipt'
                    },
                    {
                        id: 'placene-sluzby',
                        label: this.$t('c_breadcrumbs.c_registrar.c_paid_services'),
                        route: 'placene-sluzby',
                        icon: 'fas fa-bullhorn'
                    },
                    {
                        id: 'zadosti-lekaru',
                        label: this.$t('c_breadcrumbs.c_district_association.c_doctors_requests'),
                        route: 'zadosti-lekaru',
                        icon: 'fad fa-file-medical'
                    },
                    {
                        id: 'videa-prenosy',
                        label: this.$t('c_breadcrumbs.c_doctor.c_videos'),
                        route: 'videa-prenosy',
                        icon: 'fas fa-video'
                    },
                    {
                        id: 'reporty',
                        label: this.$t('c_breadcrumbs.c_super_admin.c_reports'),
                        route: 'reporty',
                        icon: 'fas fa-chart-bar'
                    },
                    {
                        id: 'elearning-courses',
                        label: this.$t('c_breadcrumbs.c_doctor.c_courses'),
                        externalLink: 'https://eclk.cz/admin-login',
                        icon: 'fad fa-chalkboard-teacher'
                    }
                ],
                district_association: [
                    {
                        id: 'lekari',
                        label: this.$t('c_breadcrumbs.c_cmc.c_doctors'),
                        route: 'lekari',
                        icon: 'fad fa-user-nurse'
                    },
                    {
                        id: 'zadosti-lekaru',
                        label: this.$t('c_breadcrumbs.c_district_association.c_doctors_requests'),
                        route: 'zadosti-lekaru',
                        badge: this.daRequestsCount,
                        icon: 'fad fa-file-medical'
                    },
                    {
                        id: 'nova',
                        label: this.$t('c_common.c_buttons.c_createActivity'),
                        route: 'aktivity-nova',
                        icon: 'fas fa-plus'
                    },
                    {
                        id: 'zadosti',
                        label: this.$t('c_DA.c_request.c_menuTitle'),
                        route: 'zadosti',
                        badge: this.requestsCount,
                        icon: 'fad fa-file-signature'
                    },
                    {
                        id: 'aktivity',
                        label: this.$t('c_breadcrumbs.c_registrar.c_activities'),
                        route: 'aktivity',
                        icon: 'fad fa-graduation-cap'
                    },
                    {
                        id: 'diplomy',
                        label: this.$t('c_breadcrumbs.c_district_association.c_diplomas'),
                        route: 'diplomy',
                        icon: 'fad fa-file-certificate'
                    }
                ]
            };
        },
        requestsCount() {
            return this.$store.getters.getRequestsCount;
        },
        daRequestsCount() {
            return this.$store.getters.getDaRequestsCount;
        },
        doctorRequestsCount() {
            return this.$store.getters.getDoctorRequestsCount;
        },
        showLoginModal() {
            return this.$store.getters.getShowAuthLogin;
        }
    },
    watch: {
        requestsCount() {
            this.$store.commit('setUserMenu', this.$_userRole ? this.roles[this.$_userRole] : []);
        },
        daRequestsCount() {
            this.$store.commit('setUserMenu', this.$_userRole ? this.roles[this.$_userRole] : []);
        },
        doctorRequestsCount() {
            this.$store.commit('setUserMenu', this.$_userRole ? this.roles[this.$_userRole] : []);
        },
        showLoginModal() {
            if (!this.showLoginModal) {
                this.$_userRole = getUserRole();
                this.$_userStatus = getUserStatus();
                this.$store.commit('setUserMenu', this.$_userRole ? this.roles[this.$_userRole] : []);
            }
        }
    },
    mounted() {
        this.$_userRole = getUserRole();
        this.$_userStatus = getUserStatus();
        if (this.roles) {
            this.$store.commit('setUserMenu', this.$_userRole ? this.roles[this.$_userRole] : []);
        } else {
            setTimeout(() => {
                // eslint-disable-next-line
                console.error('roles: ' + this.roles);
                this.$store.commit('setUserMenu', this.$_userRole ? this.roles[this.$_userRole] : []);
            }, 200);
        }
        if (this.defaultActiveItem) {
            this.$store.commit('setDefaultActiveItem', this.$_userRole ? this.defaultActiveItem[this.$_userRole] : {});
        } else {
            setTimeout(() => {
                // eslint-disable-next-line
                console.error('defaultActiveItem: ' + this.defaultActiveItem);
                this.$store.commit('setDefaultActiveItem', this.$_userRole && this.defaultActiveItem ? this.defaultActiveItem[this.$_userRole] : {});
            }, 500);
        }
    },
    methods: {
        defaultRoute({ defaultRoutes }) {
            if (getUserRole() && getUserStatus()) {
                if (getUserRole() === 'registrar' && getUserStatus() !== 'approved') {
                    return 'registrator-schvaleni-registrace';
                }
            }
            return defaultRoutes[getUserRole()];
        },
        $getDefaultRoleRoute(role) {
            if (getUserRole() && getUserStatus()) {
                if (getUserRole() === 'registrar' && getUserStatus() !== 'approved') {
                    return 'registrator-schvaleni-registrace';
                }
            }
            return this.defaultRoutes[role];
        }
    }
};
