<template>
    <div>
        <cookies-bar
            v-if="showCookiesBar"
            @onSettingButton="onSettingButton"
            @onAcceptAll="onAcceptAll"
        />
        <cookies-modal
            v-if="showCookiesModalComputed"
            :saved-analytics-cookies="settings.analyticsCookies"
            :saved-advertisement-cookies="settings.advertisementCookies"
            @onSaveSettings="onSaveSettings"
        />
    </div>
</template>

<script>
    import CookiesBar from '../components/CookiesBar.vue';
    import CookiesModal from '../components/CookiesModal.vue';
    import { getCookiesConfirmed, setCookiesConfirmed, getCookiesSettings, setCookiesSettings } from '@/utils/localStorageUtils';
    export default {
        name: 'CookiesSettings',
        components: { CookiesBar, CookiesModal },
        data() {
            return {
                showCookiesModal: false,
                showCookiesBar: false,
                settings: {
                    analyticsCookies: false,
                    advertisementCookies: false
                }
            };
        },
        computed: {
            showCookiesModalComputed() {
                return this.$store.getters.getShowCookiesModal || this.showCookiesModal;
            }
        },
        watch: {
            '$store.getters.getShowCookiesModal'() {
                this.updateSettings();
            }
        },
        mounted() {
            this.showCookiesBar = !getCookiesConfirmed();
            const settings = getCookiesSettings();
            if (settings && settings.analyticsCookies) {
                this.$gtm.enable(true);
            } else {
                this.$gtm.enable(false);
            }
        },
        methods: {
            updateSettings() {
                this.settings = getCookiesSettings();
                if (this.settings.analyticsCookies) {
                    this.$gtm.enable(true);
                } else {
                    this.$gtm.enable(false);
                }

                this.$store.commit('setAdvertisementCookies', this.settings.advertisementCookies);
            },
            onSettingButton() {
                this.showCookiesModal = true;
            },
            onAcceptAll() {
                const analyticsCookies = true;
                const advertisementCookies = true;
                this.showCookiesModal = false;
                setCookiesConfirmed();
                setCookiesSettings({
                    analyticsCookies,
                    advertisementCookies
                });
                this.showCookiesBar = false;
                this.$store.commit('setShowCookiesModal', false);
            },
            onSaveSettings(settings) {
                setCookiesConfirmed();
                setCookiesSettings(settings);
                this.showCookiesBar = false;
            }

        }
    };
</script>

<style>

</style>