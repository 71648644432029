<template>
    <v-dialog
        :value="value"
        persistent
        width="450"
        @input="$emit('input', $event)"
    >
        <v-card>
            <v-card-title class="headline">
                <i class="fad fa-sign-out-alt mr-4"/> {{ $t('c_common.c_titles.c_logOutModal') }}
            </v-card-title>
            <v-card-text>
                {{ $t('c_common.c_modals.c_automaticLogOutText') }}
                <v-checkbox v-model="logoutAllDevices" :label="$t('c_common.c_modals.c_logOutFromAllDevice')"/>
                <div class="button-wrapper">
                    <v-btn text @click="onCancelClick">{{ $t('c_common.c_buttons.c_cancel') }}</v-btn>
                    <v-btn
                        :loading="loading"
                        class="secondary-button"
                        @click="userLogout"
                    >
                        {{ $t('c_common.c_buttons.c_logout') }}
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import { logoutUser, clearLocalStorage } from '~/utils/localStorageUtils';
    import tokenMixin from '~/mixins/tokenMixin';

    export default {
        name: 'ModalLogout',
        mixins: [tokenMixin],
        props: {
            value: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                logoutAllDevices: false,
                loading: false
            };
        },
        methods: {
            async userLogout() {
                try {
                    this.loading = true;
                    this.$store.commit('setShowLogoutOverlay', true);
                    await this.$_revokeToken(this.logoutAllDevices);
                } finally {
                    this.loading = false;
                    setTimeout(async() => {
                        this.$store.commit('logout');
                        clearLocalStorage();
                        logoutUser();
                        await this.$router.push({ name: 'index' });
                        this.$store.commit('setShowLogoutOverlay', false);
                    }, 300);
                }
            },
            onCancelClick() {
                this.$store.commit('setLogoutClick', false);
            }
        }
    };
</script>

<style lang="scss" scoped>
@import '././assets/scss/variables';

    .button-wrapper {
        display: flex;
        justify-content: flex-end;

        .v-btn:first-of-type {
            margin-right: 1rem;
        }
    }

    @media screen and (max-width: breakpoint('sm')) {
        .button-wrapper{
            flex-direction: column-reverse;

            .v-btn:first-of-type {
                margin-right: 0;
                margin-top: 1rem;
            }
        }

        .fa-sign-out-alt {
            display: none;
        }
    }
</style>