import Vue from 'vue';
import VueI18n from 'vue-i18n';
import cs from '../i18n/cs.json';
Vue.use(VueI18n);
export const i18n = new VueI18n({
    locale: 'cs',
    fallbackLocale: 'cs',
    messages: {
        cs: cs
    },
    pluralizationRules: {
        /**
         * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
         * @param choicesLength {number} an overall amount of available choices
         * @returns a final choice index to select plural word by
         */
        cs(choice, choicesLength) {
            // this === VueI18n instance, so the locale property also exists here
            if (choice === 0) {
                return 0;
            } else if (choice === 1) {
                return 1;
            } else if (choice >= 2 && choice <= 4) {
                return 2;
            } else {
                return 3;
            }
        }
    }
});
export default ({ app, store }) => {
    app.i18n = i18n;
};
