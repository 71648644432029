import { czechRoleName } from '@/utils/stringUtils';
import { getUserRole, getUserStatus } from '@/utils/localStorageUtils';

export default {
    data() {
        return {
            userStatus: '',
            userRoleMixin: ''
        };
    },
    computed: {
        approvedUser() {
            return this.userStatus === 'approved';
        },
        roles() {
            return {
                cmc: [
                    this.getSettingsItem('cmc'),
                    this.getLogoutItem()
                ],
                doctor: [
                    this.getProfileItem('doctor'),
                    this.getSettingsItem('doctor'),
                    this.getLogoutItem()
                ],
                registrar: this.approvedUser
                    ? [this.getProfileItem('registrar'), this.getSettingsItem('registrar'), this.getLogoutItem()]
                    : [this.getSettingsItem('registrar'), this.getLogoutItem()],
                super_admin: [
                    this.getLogoutItem()
                ],
                district_association: [
                    this.getSettingsItem('district_association'),
                    this.getLogoutItem()
                ]
            };
        },
        showLoginModal() {
            return this.$store.getters.getShowAuthLogin;
        }
    },
    watch: {
        showLoginModal() {
            if (!this.showLoginModal) {
                this.userStatus = getUserStatus();
                this.userRoleMixin = getUserRole();

                this.$store.commit('setAppBarMenu', this.userRoleMixin ? this.roles[this.userRoleMixin] : []);
            }
        }
    },
    mounted() {
        this.userStatus = getUserStatus();
        this.userRoleMixin = getUserRole();

        this.$store.commit('setAppBarMenu', this.userRoleMixin ? this.roles[this.userRoleMixin] : []);
    },
    methods: {
        getProfileItem(role) {
            return {
                id: 'profile',
                label: this.$t('c_settings.c_editProfile'),
                action: {
                    name: 'route',
                    routeName: `${czechRoleName(role)}-nastaveni-profil`
                },
                icon: 'fad fa-user-edit fa-lg'
            };
        },
        getSettingsItem(role) {
            return {
                id: 'settings',
                label: this.$t('c_breadcrumbs.c_cmc.c_settings'),
                action: {
                    name: 'route',
                    routeName: `${czechRoleName(role)}-nastaveni`
                },
                icon: 'fas fa-cog fa-lg fa-lg'
            };
        },
        getLogoutItem() {
            return {
                id: 'logout',
                label: this.$t('c_common.c_buttons.c_logout'),
                action: {
                    name: 'logout'
                },
                icon: 'fad fa-sign-out-alt fa-lg'
            };
        }
    }
};
