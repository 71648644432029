import { roundNumber } from './numberUtils';

export function snake2Pascal(str) {
    str += '';
    str = str.split('_');

    function upper(str) {
        return str.slice(0, 1).toUpperCase() + str.slice(1, str.length);
    }

    for (let i = 0; i < str.length; i++) {
        const str2 = str[i].split('/');
        for (let j = 0; j < str2.length; j++) {
            str2[j] = upper(str2[j]);
        }
        str[i] = str2.join('');
    }
    return str.join('');
}

export function czechRoleName(role) {
    switch (role) {
        case 'admin': return 'admin';
        case 'cmc': return 'clk';
        case 'registrar': return 'registrator';
        case 'doctor': return 'lekar';
        case 'district_association': return 'okresni-sdruzeni';
    }
}

export function localizeNumber(number) {
    if (typeof number === 'number') {
        if (Number.isInteger(number)) {
            return number.toLocaleString('cs-CZ');
        } else {
            return Math.floor(number).toLocaleString('cs-CZ') + number.toString().slice(number.toString().indexOf('.'));
        }
    }
    return null;
}

export function roundLocalizeNumber(number, scale) {
    if (typeof number === 'number') {
        if (Number.isInteger(number)) {
            return number.toLocaleString('cs-CZ') + (0).toFixed(scale).slice(1);
        } else {
            let roundNum = roundNumber(number, scale);
            const numAfterPoint = roundNum.toString().slice(roundNum.toString().indexOf('.') + 1);
            if (numAfterPoint.length < scale) {
                roundNum = roundNum.toFixed(scale);
            }
            return Math.floor(roundNum).toLocaleString('cs-CZ') + roundNum.toString().slice(roundNum.toString().indexOf('.'));
        }
    }
    return null;
}

export function routeWithQueryParams(routePath, queryParams) {
    let path = routePath;
    Object.keys(queryParams).forEach((param, paramIndex) => {
        path += (paramIndex === 0 ? '?' : '&') + `${param}=${queryParams[param]}`;
    });
    return path;
}
