import { helpers } from 'vuelidate/lib/validators';
import moment from 'moment';

/**
 * Vuelidate error messages translator using vue-18n
 *
 * Mixin add methods to observe localized messages from error field.
 */
export const vuelidateMessagesMixin = {
    methods: {
        $getFieldErrors(field) {
            return Object.entries(field)
                .filter(([key, value]) => !key.startsWith('$') && typeof value === 'boolean' && !value)
                .map(([key]) => {
                    const type = field.$params[key].type;
                    const message = field.$params[key].message;
                    const params = { ...field.$params[key] };
                    if (message) {
                        const localizedMessage = message.startsWith('c_') ? this.$t(message, params) : '';
                        return localizedMessage !== '' ? localizedMessage : message;
                    } else {
                        const globalMessage = this.$t(`c_vuelidate.c_${type}`, params);
                        return globalMessage !== '' ? globalMessage : key;
                    }
                });
        },
        $getFieldError(field) {
            const errors = this.$getFieldErrors(field);
            return errors && errors.length ? errors[0] : '';
        }
    }
};

// === Validators =========================================

const URL_PATTERN = /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00A1-\uFFFF0-9]-*)*[a-z\u00A1-\uFFFF0-9]+)(?:\.(?:[a-z\u00A1-\uFFFF0-9]-*)*[a-z\u00A1-\uFFFF0-9]+)*(?:\.(?:[a-z\u00A1-\uFFFF]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
const EMAIL_PATTERN = /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;
const INTEGER_PATTERN = /^-?[0-9]*$/;
const NUMERIC_PATTERN = /^[0-9]*$/;
const PHONE_NUMBER_PATTERN = /^[1-9][0-9]{8}$/;
const ALPHA_PATTERN = /^[A-zÀ-ú]+$/;
const VAT_NUMBER = /^[0-9]{8}$/;
const VERIFICATION_CODE = /^[0-9]{6}$/;
const ZIP_CODE = /^[0-9]{5}$/;
const CMC_ID_PATTERN = /^[0-9]{10}$/;
const MEETING_ID_PATTERN = /^([0-9]{9}|[0-9]{10}|[0-9]{11})$/;
const ELEARNING_CREDITS = /^([2-9]|[1-9][0-9]|[1-9][0-9][0-9]|1000)$/;
const ACTIVITY_CREDITS = /^([2-9]|[1-9][0-9]|[1-9][0-9][0-9])$/;
const LIST_ELEARNING_CREDITS = /^([0-9]|[1-9][0-9]|[1-9][0-9][0-9]|1000)$/;
const LIST_ACTIVITY_CREDITS = /^([0-9]|[1-9][0-9]|[1-9][0-9][0-9])$/;
const XLSX_FILE = /^.+\.(?:(?:[xX][lL][sS][xX]))$/;

function checkPass(pass) {
    if (!pass) { return false; }
    if (pass.length < 8) { return false; }

    let counter = 0;

    if (/[A-Z]/.test(pass)) { counter++; }
    if (/[a-z]/.test(pass)) { counter++; }
    if (/[0-9]/.test(pass)) { counter++; }
    if (/[^a-zA-Z0-9]/.test(pass)) { counter++; }

    return counter >= 3;
}

const regexHelper = (type, pattern, message) => helpers.withParams(
    { type, pattern, message },
    value => !helpers.req(value) || pattern.test(value));

// --- Original Vuelidate validators extended for message param ---
export const required = (required = true, message) => {
    if (typeof message === 'undefined') {
        if (typeof required === 'string') {
            // Syntax: required(message)
            message = required;
            required = true;
        }
    }

    return helpers.withParams(
        { type: 'required', required, message },
        (value) => {
            // Value cant'be white space
            if (required && !/\S/.test(value)) { return false; }
            return required ? helpers.req(value) : true;
        }
    );
};

export const regexp = (pattern, message) => regexHelper('regexp', pattern, message);

export const maxLength = (max, message) => helpers.withParams(
    { type: 'maxLength', max, message },
    value => !helpers.req(value) || helpers.len(value) <= max);

export const minLength = (min, message) => helpers.withParams(
    { type: 'minLength', min, message },
    value => !helpers.req(value) || helpers.len(value) >= min);

export const url = message => regexHelper('url', URL_PATTERN, message);

export const email = message => regexHelper('email', EMAIL_PATTERN, message);

export const minValue = (min, message) => helpers.withParams(
    { type: 'minValue', min, message },
    value => !helpers.req(value) ||
        ((!/\s/.test(value) || value instanceof Date) && +value >= +min));

export const maxValue = (max, message) => helpers.withParams(
    { type: 'maxValue', max, message },
    value => !helpers.req(value) ||
        ((!/\s/.test(value) || value instanceof Date) && +value <= +max));

// --- Custom validators

/**
 * Expect Boolean value. "false" value indicates that model has validation error.
 */

export const integer = message => regexHelper('integer', INTEGER_PATTERN, message);

export const numeric = message => regexHelper('numeric', NUMERIC_PATTERN, message);

export const double = message => helpers.withParams(
    { type: 'numeric', message },
    value => !helpers.req(value) || !isNaN(parseFloat(value, 10)));

export const alpha = message => regexHelper('alpha', ALPHA_PATTERN, message);

export const phone = message => regexHelper('phone', PHONE_NUMBER_PATTERN, message);

export const vat = message => regexHelper('vat', VAT_NUMBER, message);

export const verificationCode = message => regexHelper('verificationCode', VERIFICATION_CODE, message);

export const zipCode = message => regexHelper('zipCode', ZIP_CODE, message);

export const cmcId = message => regexHelper('cmcId', CMC_ID_PATTERN, message);

export const xlsxFileName = message => regexHelper('xlsx_file_name', XLSX_FILE, message);

export const elearningCredits = message => regexHelper('elearningCredits', ELEARNING_CREDITS, message);

export const activityCredits = message => regexHelper('activityCredits', ACTIVITY_CREDITS, message);

export const listElearningCredits = message => regexHelper('elearningCredits', LIST_ELEARNING_CREDITS, message);

export const listActivityCredits = message => regexHelper('activityCredits', LIST_ACTIVITY_CREDITS, message);

export const meetingId = message => regexHelper('meetingId', MEETING_ID_PATTERN, message);

export const endDate = (startDate, message) => helpers.withParams(
    { type: 'endDate', startDate, message },
    (value) => {
        return startDate
            ? !helpers.req(value) || moment(value).isAfter(moment(startDate).add(-1, 'days')) : true;
    }
);

export const endDateTime = (startDate, startTime, endDate, message) => helpers.withParams(
    { type: 'endDateTime', startDate, startTime, endDate, message },
    (value) => {
        if (startDate && startTime && endDate && value) {
            // Parse start time
            const startHour = startTime.substring(0, startTime.indexOf(':'));
            const startMinute = startTime.substring(startTime.indexOf(':') + 1);
            // Set moment start date time
            const startDateTime = moment(startDate).add(1, 'days').utc().set({ 'hour': startHour, 'minute': startMinute });
            // Parse end time
            const endHour = value.substring(0, value.indexOf(':'));
            const endMinute = value.substring(value.indexOf(':') + 1);
            // Set moment start date time
            const endDateTime = moment(endDate).add(1, 'days').utc().set({ 'hour': endHour, 'minute': endMinute });
            return !helpers.req(value) || moment(endDateTime).isSameOrAfter(moment(startDateTime));
        } else {
            return true;
        }
    }
);

export const dateBetween = (startDate, endDate, message) => helpers.withParams(
    { type: 'dateBetween', startDate, endDate, message },
    (value) => {
        if (startDate && endDate && value) {
            const dateBetweenStartEnd = moment(value).isAfter(moment(startDate).add(-1, 'days')) &&
                moment(value).isBefore(moment(endDate).add(1, 'days'));
            return !helpers.req(value) || dateBetweenStartEnd;
        } else {
            return true;
        }
    }
);

export const dateTimeBetween = (startDateTime, endDateTime, dateBetween, message) => helpers.withParams(
    { type: 'dateTimeBetween', startDateTime, endDateTime, message },
    (value) => {
        if (startDateTime && endDateTime && dateBetween && value) {
            // Parse between time
            const betweenHour = value.substring(0, value.indexOf(':'));
            const betweenMinute = value.substring(value.indexOf(':') + 1);
            // Set moment start date time
            const betweenDateTime = moment(dateBetween).add(1, 'days').utc().set({ 'hour': betweenHour, 'minute': betweenMinute });

            const dateTimeBetweenStartEnd = moment(betweenDateTime).isAfter(moment(startDateTime)) &&
                moment(betweenDateTime).isBefore(moment(endDateTime));
            return !helpers.req(value) || dateTimeBetweenStartEnd;
        } else {
            return true;
        }
    }
);

export const yearInterval = (startDate, message) => helpers.withParams(
    { type: 'yearInterval', startDate, message },
    (value) => {
        return startDate
            ? !helpers.req(value) || moment(value).isBefore(moment(startDate).add(1, 'years')) : true;
    }
);

export const password = message => helpers.withParams(
    { type: 'password', message },
    value => !helpers.req(value) || checkPass(value)
);
