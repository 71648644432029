<template>
    <div :class="{ opened: show }" class="layer login">
        <div class="over">
            <div class="closelayer" @click="closeLoginModal"/>
            <div class="block">
                <div class="closelayer" @click="closeLoginModal"/>
                <div class="overinner">
                    <a class="ic_close_layer" @click="closeLoginModal"/>
                    <div class="inner position-relative">
                        <header>
                            <h2 class="frank-bold">{{ $t("c_home.c_registration") }}</h2>
                        </header>
                        <div>
                            <v-row class="justify-center">
                                <v-col cols="12" md="auto">
                                    <v-card class="registration-card doctor">
                                        <v-card-title>
                                            <h4>{{ $t("c_registration.c_type.c_doctor") }}</h4>
                                        </v-card-title>
                                        <div class="card-image">
                                            <img
                                                src="/img/doctor_image.png"
                                                class="registration-image"
                                            >
                                        </div>
                                        <!-- eslint-disable vue/no-v-html -->
                                        <v-card-text
                                            v-html="
                                                $t('c_registration.c_doctor.c_registrateModalText')
                                            "
                                        />
                                        <!--eslint-enable-->
                                        <v-card-actions>
                                            <nuxt-link
                                                :to="{ name: 'registrace-zaslat-odkaz-k-registraci' }"
                                                tag="button"
                                                class="registration-button"
                                                @click.native="closeLoginModal"
                                            >
                                                {{ $t("c_common.c_buttons.c_registrate") }}
                                            </nuxt-link>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="auto">
                                    <v-card class="registration-card registrar">
                                        <v-card-title>
                                            <h4>{{ $t("c_registration.c_type.c_registrar") }}</h4>
                                        </v-card-title>
                                        <div class="card-image">
                                            <img src="/img/registrar_image.png">
                                        </div>
                                        <v-card-text>
                                            {{
                                                $t("c_registration.c_registrar.c_registrateModalText")
                                            }}
                                        </v-card-text>
                                        <v-card-actions>
                                            <nuxt-link
                                                :to="{ name: 'registrace-registrator' }"
                                                tag="button"
                                                class="registration-button"
                                                @click.native="closeLoginModal"
                                            >
                                                {{ $t("c_common.c_buttons.c_registrate") }}
                                            </nuxt-link>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { removeHtmlClass } from '@/utils/htmlUtils';

    export default {
        name: 'AppRegistration',
        props: {
            show: {
                type: Boolean,
                required: true
            }
        },
        beforeDestroy() {
            this.removeNoscrollClass();
        },
        methods: {
            closeLoginModal() {
                this.removeNoscrollClass();
                this.$emit('update:show', false);
            },
            removeNoscrollClass() {
                removeHtmlClass('noscroll');
                document.body.classList.remove('noscroll');
            }
        }
    };
</script>

<style lang="scss" scoped>
@import "./assets/scss/variables";

.v-application
  .v-application--wrap
  .landing-page-wrapper
  .layer.login
  .block
  .overinner {
  max-width: 75rem;
  background: url("/img/registration_modal_bcg.png");
  background-size: cover;
}

.check {
  + ::v-deep input {
    display: none;
    visibility: hidden;
  }
}

.v-card__title {
  word-break: break-word;
}

button {
  margin: auto;
}

.v-card__text {
  font-size: 1rem;
  color: color("font-primary-color");
  min-height: 13rem;
}

.v-card__title {
  word-break: break-word;
}

.registration-card {
  height: 100%;
  max-width: 25rem;
  padding: 0 1rem 2rem 1rem;
  margin: auto;

  @media only screen and (max-width: 320px) {
    .registration-button {
      font-size: 1rem !important;
    }
  }
}

.registration-card.doctor {
  border-top: 0.5rem solid color("primary");

  &:hover {
    box-shadow: $box-shadow-doctor;
  }
}

.registration-card.registrar {
  border-top: 0.5rem solid color("green");

  &:hover {
    box-shadow: $box-shadow-registrar;
  }

  .v-card__actions button {
    border: 2px solid color("green");
    color: color("green");

    &:hover {
      background: color("green");
    }
  }
}

.registration-card.public {
  border-top: 0.5rem solid #e5e5e5;
}

.landing-page-wrapper .over .overinner button {
  border-radius: 2px;
}

.v-application .v-application--wrap .landing-page-wrapper .layer h2 {
  margin-bottom: 1rem;
}

.card-image {
  height: 16rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 1rem;
  img {
    width: 10.5rem;
  }
}

@media only screen and (max-width: 1100px) {
  h4 {
    font-size: 1.4rem;
  }

  .v-application
    .v-application--wrap
    .landing-page-wrapper
    .layer
    .block
    .overinner
    .inner {
    padding: 3rem;
  }
}
</style>
