<template>
    <div>
        <v-card id="dashboard">
            <div id="dashboard-wrapper">
                <div class="dashboard-section">
                    <div class="dashboard-header">{{ roleDoctor ? $t('c_doctor.c_dashboard.c_yourCredist') : $t('c_doctor.c_dashboard.c_credits') }}</div>
                    <div class="d-flex justify-center">
                        <div id="current-credits" class="dashboard-content">
                            <div class="credits-number"> {{ currentCredits }} </div>
                            <div>{{ $t('c_doctor.c_dashboard.c_currentCredits') }}</div>
                        </div>
                        <v-divider vertical class="large-display-divider vertical-separator"/>
                        <div id="total-credits" class="dashboard-content">
                            <div class="credits-number"> {{ statistics.total_credits }} </div>
                            <div>{{ $t('c_doctor.c_dashboard.c_totalCredits') }}</div>
                        </div>
                    </div>
                </div>
                <v-divider vertical class="small-display-divider"/>
                <div class="dashboard-section">
                    <v-divider class="large-display-divider mb-4"/>
                    <div class="dashboard-header">{{ roleDoctor ? $t('c_doctor.c_dashboard.c_specialisation') : $t('c_cmc.c_doctors.c_labels.c_specialisation') }}</div>
                    <div class="dashboard-content">
                        <div
                            v-for="(speciality, index) in specialities"
                            :key="speciality.id"
                            class="text"
                        >
                            {{ index === specialities.length - 1 ? speciality.name : speciality.name + ', ' }}
                        </div>
                        <div v-if="!roleDA">
                            <v-divider class="large-display-divider my-4"/>
                            <div class="dashboard-header da-name">{{ $t('c_doctor.c_dashboard.c_additionalInfo') }}</div>
                            <div>{{ $t('c_doctor.c_dashboard.c_DA') }}: {{ statistics.district_association_name }}</div>
                            <div>{{ $t('c_doctor.c_dashboard.c_cmcID') }}: {{ statistics.cmc_id }}</div>
                        </div>
                    </div>
                </div>
                <v-divider vertical class="small-display-divider"/>
                <div class="dashboard-section">
                    <v-divider class="large-display-divider mt-2 mb-4"/>
                    <div class="dashboard-header">{{ $t('c_doctor.c_dashboard.c_diploma') }}</div>
                    <div class="dashboard-content">
                        <div v-if="statistics.last_diploma_request_status && roleDoctor" class="diploma-request-status">
                            <nuxt-link :to="{ name: 'zadosti' }">
                                <i class="far fa-file-certificate mr-2"/> {{ $t('c_doctor.c_dashboard.c_applicationStatus') }}
                                <span :class="classes">
                                    {{ $t(`c_requests.c_statusRegistrar.c_${statistics.last_diploma_request_status}`) }}
                                </span>
                            </nuxt-link>
                        </div>
                        <div v-if="statistics && statistics.diploma_valid_until" class="text"><i class="far fa-calendar-alt mr-2"/> {{ $t('c_doctor.c_dashboard.c_diplomaValidUntil') }} <span class="no-wrap">{{ czechFormatDate(statistics.diploma_valid_until) }}</span> </div>
                        <div v-else class="text">{{ roleDoctor ? $t('c_doctor.c_dashboard.c_notValidDiploma') : $t('c_doctor.c_dashboard.c_doctorHasNotValidDiploma') }}</div>
                        <div v-if="statistics && statistics.next_diploma_valid_from" class="text mt-4"><i class="far fa-calendar-alt mr-2"/> {{ $t('c_doctor.c_dashboard.c_nextDiplomaValidFrom') }} <span class="no-wrap">{{ czechFormatDate(statistics.next_diploma_valid_from) }}</span> </div>
                        <div class="mt-4 text-center">
                            <a href="https://soubory-vzdelavani-l.b-cdn.net/Podmínky%20pro%20získání%20diplomu.pdf" target="_blank">
                                <i class="far fa-external-link mr-2"/>  {{ $t('c_doctor.c_dashboard.c_conditionForDiploma') }}
                            </a>
                        </div>
                        <v-btn
                            v-if="showRequestDiplomaButton && roleDoctor"
                            class="request-btn"
                            color="primary"
                            text
                            @click="onRequestForDiploma()"
                        >
                            {{ $t('c_doctor.c_dashboard.c_requestForDiploma') }}
                        </v-btn>
                    </div>
                    <v-divider class="large-display-divider mt-2 mb-4"/>
                </div>
                <v-divider vertical class="small-display-divider"/>
                <div class="dashboard-section">
                    <div class="dashboard-header">{{ $t('c_doctor.c_dashboard.c_remain') }} {{ statistics.diploma_valid_until ? $t('c_doctor.c_dashboard.c_toRestore') : $t('c_doctor.c_dashboard.c_toObtain') }} {{ $t('c_doctor.c_dashboard.c_forDiploma') }}</div>
                    <div class="dashboard-content">
                        <v-progress-circular
                            :size="150"
                            :width="10"
                            :value="currentCreditsPlot"
                            color="primary"
                        >
                            <div>
                                <animated-number
                                    :value="leftCredits"
                                    class="frank-bold count"
                                    :format-value="number => Math.ceil(number)"
                                    :duration="1000"
                                />
                            </div>
                            <div id="credits-text">{{ $t('c_doctor.c_dashboard.c_creditsAlt') }}</div>
                        </v-progress-circular>
                    </div>
                </div>
            </div>
        </v-card>
        <v-dialog v-model="showDialog" max-width="500">
            <v-card>
                <v-card-title class="headline">
                    {{ $t('c_doctor.c_dashboard.c_requestDiplomaDialogTitle') }}
                </v-card-title>
                <v-card-text v-if="statistics">
                    <p>{{ $t('c_doctor.c_dashboard.c_requestDiplomaDialogText1') }}</p>
                    <p>{{ $t('c_doctor.c_dashboard.c_requestDiplomaDialogText2') }}<strong> {{ czechFormatDate(statistics.diploma_valid_until) }}</strong></p>
                    <div class="btn-wrapper">
                        <v-btn
                            text
                            class="gray-btn"
                            @click="showDialog = false"
                        >
                            {{ $t('c_doctor.c_dashboard.c_requestDiplomaDialogButtonRequestLater') }}
                        </v-btn>
                        <v-btn
                            color="primary"
                            text
                            class="secondary-button"
                            @click="onDialogRequestForDiploma"
                        >
                            {{ $t('c_doctor.c_dashboard.c_requestDiplomaDialogButtonRequestNow') }}
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import AnimatedNumber from 'animated-number-vue';
    import moment from 'moment';
    import { czechFormatDate } from '@/utils/dateUtils';

    const MAX_CREDITS = 150;

    export default {
        components: { AnimatedNumber },
        props: {
            specialities: {
                type: Array,
                required: true
            },
            doctorId: {
                type: Number,
                required: true
            },
            userRole: {
                type: String,
                default: 'doctor'
            },
            statistics: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                currentCredits: 0,
                currentCreditsPlot: 0,
                leftCredits: 0,
                showDialog: false,
                czechFormatDate
            };
        },
        computed: {
            pendingUpdateStatus() {
                return this.statistics.last_diploma_request_status === 'updated_approver' ||
                    this.statistics.last_diploma_request_status === 'updated_applicant' ||
                    this.statistics.last_diploma_request_status === 'pending';
            },
            lessThanThirtyDaysDiplomaRule() {
                const today = moment();
                const diplomaValidUntil = moment(this.statistics.diploma_valid_until);
                return diplomaValidUntil.isValid() ? diplomaValidUntil.diff(today, 'days') <= 30 : true;
            },
            showRequestDiplomaButton() {
                return this.statistics.current_credits >= MAX_CREDITS && !this.pendingUpdateStatus && !this.statistics.next_diploma_valid_from && this.lessThanThirtyDaysDiplomaRule;
            },
            classes() {
                return {
                    'status-waiting-text': this.statistics.last_diploma_request_status === 'updated_approver',
                    'status-update-text': this.statistics.last_diploma_request_status === 'updated_applicant' || this.statistics.last_diploma_request_status === 'pending',
                    'status-denied-text': this.statistics.last_diploma_request_status === 'denied',
                    'status-approved-text': this.statistics.last_diploma_request_status === 'approved'
                };
            },
            roleDoctor() {
                return this.userRole === 'doctor';
            },
            roleDA() {
                return this.userRole === 'district_association';
            }
        },
        watch: {
            // setTimeout for synchronization between leftCredits and animated-number component
            statistics: {
                immediate: true,
                handler() {
                    setTimeout(() => {
                        this.currentCredits = this.statistics.current_credits;
                        this.currentCreditsPlot = (this.currentCredits / MAX_CREDITS) * 100;
                    }, 200);
                    this.leftCredits = (MAX_CREDITS - this.statistics.current_credits) > 0
                        ? (MAX_CREDITS - this.statistics.current_credits) : 0;
                }
            }
        },
        methods: {
            onRequestForDiploma() {
                const diplomaValidUntil = moment(this.statistics.diploma_valid_until);
                const daysDifference = diplomaValidUntil.diff(moment(), 'days');
                daysDifference > 123 ? this.showDialog = true : this.$router.push({ name: 'lekar-nova-zadost' });
            },
            onDialogRequestForDiploma() {
                this.$router.push({ name: 'lekar-nova-zadost' });
                this.showDialog = false;
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import '../assets/scss/variables';

    #dashboard {
        border-radius: 1rem;
        box-shadow: none;
        color: gray;
        width: 23rem;
        padding: 1.5rem;

        a {
            color: color('primary');
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        .status-update-text {
            white-space: nowrap;
        }

        .diploma-request-status {
            margin-bottom: 1rem;
            text-align: center;

            a {
                color: gray;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        ::v-deep .v-progress-circular__info {
            display: block;

            #credits-text {
                color: gray;
            }
        }

        #dashboard-wrapper {

            display: flex;
            flex-direction: column;

            .dashboard-section {
                .vertical-separator {
                    height: 4rem;
                    margin-top: 1.2rem;
                }
                .dashboard-header{
                    font-size: 1.1rem;
                    color: color('font-primary-color');
                    font-weight: 500;
                    padding: 0.4rem 0;
                    text-align: center;
                }

                .da-name {
                    padding-bottom: 1rem;
                }

                .dashboard-content {
                    padding: 0.5rem 0;
                    text-align: center;
                }

                #total-credits {
                    margin-left: 2rem;
                    margin-bottom: 1rem;
                }

                #current-credits {
                    margin-right: 2rem;
                    margin-bottom: 1rem;
                }

            }

            @media only screen and (max-width:1750px) and (min-width: 950px)  {
                flex-direction: row;
                justify-content: space-between;

                .dashboard-section {
                    padding: 1rem;
                }

                .large-display-divider {
                    display: none;
                };

                .small-display-divider {
                    display: block;
                }

                .da-name {
                    margin-top: 1rem;
                }
            }

        }

        .request-btn {
            margin-top: 1rem;
            background: color('secondary');
        }

        .credits-number {
            font-size: 2rem;
            color: color('primary')
        }

        .small-display-divider {
            display: none;
        }

        .v-card__title {
            color: black;
        }

        .text-credist {
            margin-left: 1rem;
            font-size: 1rem;
        }

        .circular-wrapper {
            margin-top: 1rem;
        }

        .count {
            color: gray;
            font-size: 1.3rem;
        }
    }

    ::v-deep .v-dialog {
        .v-card {
            .btn-wrapper {
                margin-top: 3rem;
                text-align: right;

                .gray-btn {
                    background: color('light-gray');
                }
            }
        }
    }

    @media only screen and (max-width:960px) and (min-width: 300px)  {
        #dashboard {
            width: 100%;
        }
    }

    @media only screen and (max-width:1750px) and (min-width: 960px)  {

        #dashboard {
            width: 100%;

            .dashboard-section {
                max-width: 24%;
            }
        }
    }

    //IE styles

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 1320px) and (min-width: 1280px) {
        #dashboard {
            padding: 1rem;
        }

        #dashboard-wrapper {
            max-width: 56rem;
            margin: auto;
        }
    }
</style>
